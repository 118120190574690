"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _resources = _interopRequireDefault(require("../data/resources.json"));

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it["return"] != null) it["return"]();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var useResources = function useResources() {
  var _useState = (0, _react.useState)(),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      activeTree = _useState2[0],
      setActiveTree = _useState2[1];

  var _useState3 = (0, _react.useState)(),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      currentChapter = _useState4[0],
      setCurrentChapter = _useState4[1];

  var _useState5 = (0, _react.useState)(0),
      _useState6 = (0, _slicedToArray2["default"])(_useState5, 2),
      currentChapterId = _useState6[0],
      setCurrentChapterById = _useState6[1];

  (0, _react.useEffect)(function () {
    setActiveTree(_resources["default"]);
  }, []);
  (0, _react.useEffect)(function () {
    if (activeTree != undefined) {
      var chapter = getChapter(currentChapterId);
      setCurrentChapter(chapter);
    }
  }, [currentChapterId, activeTree]);

  var getChapter = function getChapter(chapterId) {
    var queue = [activeTree];
    var visitedChapters = [];

    while (queue.length) {
      var currentNode = queue.shift();

      if (currentNode.id == chapterId) {
        return currentNode;
      }

      if (currentNode.possible_chapters) {
        var _iterator = _createForOfIteratorHelper(currentNode.possible_chapters),
            _step;

        try {
          for (_iterator.s(); !(_step = _iterator.n()).done;) {
            var chapter = _step.value;

            if (!visitedChapters.includes(chapter.id)) {
              visitedChapters.push(chapter.id);
              queue.push(chapter);
            }
          }
        } catch (err) {
          _iterator.e(err);
        } finally {
          _iterator.f();
        }
      }
    }

    console.log("chapter not found");
  };

  return {
    setCurrentChapterById: setCurrentChapterById,
    currentChapter: currentChapter
  };
};

var _default = useResources;
exports["default"] = _default;