"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _Primitives = require("../primitives/Primitives");

var _AppStyles = require("../styles/AppStyles");

var _AppIcon = _interopRequireDefault(require("./AppIcon"));

var _this = void 0,
    _jsxFileName = "/home/vsts/work/1/s/common/build/web/temp/components/AppText.tsx";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var AppText = function AppText(_ref) {
  var children = _ref.children,
      containerStyle = _ref.containerStyle,
      iconContainerStyle = _ref.iconContainerStyle,
      textStyle = _ref.textStyle,
      iconName = _ref.iconName,
      iconColor = _ref.iconColor,
      iconSize = _ref.iconSize,
      alignRight = _ref.alignRight;
  return _react["default"].createElement(_Primitives.Box, {
    style: _objectSpread(_objectSpread({}, _AppStyles.AppTextStyles.container), containerStyle),
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 28,
      columnNumber: 5
    }
  }, alignRight ? null : !iconName ? null : _react["default"].createElement(_Primitives.Box, {
    style: _objectSpread(_objectSpread({}, _AppStyles.AppTextStyles.iconContainer), iconContainerStyle),
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 30,
      columnNumber: 9
    }
  }, _react["default"].createElement(_AppIcon["default"], {
    name: iconName,
    size: iconSize ? iconSize : 'medium',
    color: iconColor,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 31,
      columnNumber: 11
    }
  })), _react["default"].createElement(_Primitives.TextSpan, {
    style: _objectSpread(_objectSpread({}, _AppStyles.AppTextStyles.textStyle), textStyle),
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 38,
      columnNumber: 7
    }
  }, children), !alignRight ? null : !iconName ? null : _react["default"].createElement(_Primitives.Box, {
    style: _objectSpread(_objectSpread({}, _AppStyles.AppTextStyles.iconContainer), iconContainerStyle),
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 42,
      columnNumber: 9
    }
  }, _react["default"].createElement(_AppIcon["default"], {
    name: iconName,
    size: iconSize ? iconSize : 'medium',
    color: iconColor,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 43,
      columnNumber: 11
    }
  })));
};

var _default = AppText;
exports["default"] = _default;