import {
  VasApi,
  useApi,
  useLocalStorage
} from "@pairva/common/build/web/dist";
import * as Colors from "@pairva/common/src/utils/Colors";
import { useHistory } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import MaterialTable from "material-table";
import { userContext } from "../context/userContext";
import * as moment from 'moment';
import { getValidToken } from "../util/tokenHandler";
var Loader = require('react-loader');

const HistoryScreen = (props) => {
  const history = useHistory();
  const { user } = useContext(userContext);
  const [isLoading, setIsLoading] = useState(true);
  const [dataSource, setDataSource] = useState([]);
  const [token, setToken] = useLocalStorage("authToken");

  const {
    data: testData,
    error,
    loading,
    request
  } = useApi(VasApi.getVasChoices)

  useEffect(() => {
    getData();
  }, []);


  const getToken = async (): Promise<string> => {
    try {
      const validToken = await getValidToken(token);
      if (validToken) {
        setToken(validToken);
        return 'Bearer ' + validToken.access_token;  
      }
      else {
        setToken(undefined);
        return token;
      }  
    }
    catch (error) {
      setToken(undefined);
      return token;
    }
  }

  const getData = async () => {
    try {
      const bearerToken = await getToken();
      VasApi.apiClient.setHeader('Authorization', bearerToken);
      const requestData = await request();
      var dataArray = JSON.parse(requestData.data);
      if (dataArray == null) {
        dataArray = [];
      }
      dataArray.map((item) => {
        var stillUtc = moment.utc(item.CreatedAt).toDate();
        item.CreatedAt = moment(stillUtc).local().format('LLLL');
      });
      setDataSource(dataArray);  
    }
    catch (error) {
      setIsLoading(false);
      console.log('Error Getting History Data: ', error);
    }
  };

  const columns = [
    {
      title: "Date",
      field: "CreatedAt",
    },
    {
      title: "VAS ID",
      field: "VasId",
    }
  ]

  const content = (
    <div style={styles.container}>
      <div>
        <Loader loaded={!loading || !isLoading}>
          <MaterialTable
            style={{ backgroundColor: Colors.WHITE_LIGHT, boxShadow: 'none' }}
            actions={[
              {
                icon: 'description',
                tooltip: 'View Results',
                onClick: (event, rowData) => {
                  // Do operation
                  history.push("/vascularaccessresultsscreen", { vasId: rowData.VasId });
                }
              }
            ]}
            title="History Results"
            data={dataSource}
            columns={columns}
            options={{ search: true, paging: true, filtering: false, exportButton: false }}
          />
        </Loader>
      </div>
    </div>
  );

  return content;
};

export default HistoryScreen;
const styles = {
  container: {
    width: "100%",
    justifyContent: "center",
    alignItems: "center"
  },
  table: {
    "border-style": "none"
  },
};
