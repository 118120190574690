"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styles = void 0;

var _utils = require("../../utils");

var Styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: '13%',
    paddingRight: '13%',
    background: _utils.Colors.WHITE_LIGHT,
    justifyContent: 'center'
  },
  noteText: {
    color: _utils.Colors.BLACK,
    fontStyle: "italic",
    fontSize: _utils.Spacing.FONT_SIZE_20
  },
  noteTextContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  noteContainer: {
    marginTop: 32,
    marginBottom: 8
  },
  tabsContainer: {
    flexShrink: 1,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    flexWrap: 'wrap',
    justifySelf: 'center',
    marginTop: 20,
    width: '75%'
  },
  itemStyle: {
    flexBasis: 320,
    minWidth: 198,
    minHeight: 124,
    maxWidth: 198,
    maxHeight: 124,
    paddingTop: 12,
    paddingBottom: 12,
    paddingLeft: 18,
    paddingRight: 18,
    marginLeft: 40,
    marginRight: 40,
    marginTop: 4,
    marginBottom: 34
  }
};
exports.Styles = Styles;