"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styles = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _utils = require("../../utils");

var Styles = (0, _defineProperty2["default"])({
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 12,
    paddingRight: 12
  },
  container: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    backgroundColor: _utils.Colors.WHITE_LIGHT
  },
  nextButton: {
    backgroundColor: _utils.Colors.PRIMARY_MEDIUM,
    width: '60%',
    display: 'flex'
  },
  backAndNextSection: {
    display: "flex",
    flex: 1,
    marginBottom: 20
  },
  backButtonSection: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 15
  },
  nextButtonSection: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 15,
    marginLeft: 10
  },
  cancelButton: {
    display: "flex",
    width: '60%',
    backgroundColor: _utils.Colors.WHITE_LIGHT
  },
  title: {
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  titleText: {
    fontSize: _utils.Spacing.FONT_SIZE_24,
    textAlign: 'center'
  },
  subTitle: {
    marginTop: 10,
    marginBottom: 10,
    fontStyle: 'italic',
    textAlign: 'center'
  },
  radioButtonSection: {
    marginTop: 10,
    marginBottom: 10
  },
  moreAboutProcessSection: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5,
    marginBottom: 5,
    alignItems: 'center',
    alignSelf: 'center'
  },
  option: {}
}, "radioButtonSection", {
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: 30
});
exports.Styles = Styles;