"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _Primitives = require("../primitives/Primitives");

var _HomeMenuStyles = require("../styles/HomeMenu/HomeMenuStyles");

var _application = require("../models/application");

var _AppText = _interopRequireDefault(require("./AppText"));

var _utils = require("../utils");

var _AppTab = _interopRequireDefault(require("./AppTab"));

var _this = void 0,
    _jsxFileName = "/home/vsts/work/1/s/common/build/web/temp/components/HomeMenu.tsx";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var HomeMenu = function HomeMenu(props) {
  return _react["default"].createElement(_Primitives.Box, {
    style: _objectSpread(_objectSpread({}, _HomeMenuStyles.Styles.container), props.containerStyle),
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 21,
      columnNumber: 5
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _objectSpread(_objectSpread({}, _HomeMenuStyles.Styles.noteContainer), props.noteContainerStyle),
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 22,
      columnNumber: 7
    }
  }, _react["default"].createElement(_AppText["default"], {
    containerStyle: _HomeMenuStyles.Styles.noteTextContainer,
    textStyle: _HomeMenuStyles.Styles.noteText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 23,
      columnNumber: 9
    }
  }, "Vascular Access Individualized")), _react["default"].createElement(_Primitives.Box, {
    style: _objectSpread(_objectSpread({}, _HomeMenuStyles.Styles.tabsContainer), props.tabsContainerStyle),
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 29,
      columnNumber: 7
    }
  }, _react["default"].createElement(_AppTab["default"], {
    containerStyle: _objectSpread(_objectSpread({}, _HomeMenuStyles.Styles.itemStyle), props.itemStyle),
    iconName: "stethoscope",
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.CSA,
        props: null
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 30,
      columnNumber: 9
    }
  }, "Clinical Situation Algorithms"), _react["default"].createElement(_AppTab["default"], {
    containerStyle: _objectSpread(_objectSpread({}, _HomeMenuStyles.Styles.itemStyle), props.itemStyle),
    iconName: "star-o",
    isMaterialIcon: false,
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.VAS,
        props: null
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 38,
      columnNumber: 9
    }
  }, "Vascular Access Selection Assistant"), _react["default"].createElement(_AppTab["default"], {
    containerStyle: _objectSpread(_objectSpread({}, _HomeMenuStyles.Styles.itemStyle), props.itemStyle),
    iconName: 'book',
    isMaterialIcon: _utils.Constants.IS_WEB ? false : true,
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.RESOURCES,
        props: null
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 47,
      columnNumber: 9
    }
  }, "Resources"), props.signedIn && _react["default"].createElement(_AppTab["default"], {
    containerStyle: _objectSpread(_objectSpread({}, _HomeMenuStyles.Styles.itemStyle), props.itemStyle),
    iconName: "history",
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.HISTORY,
        props: null
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 57,
      columnNumber: 11
    }
  }, "History"), _react["default"].createElement(_AppTab["default"], {
    containerStyle: _objectSpread(_objectSpread({}, _HomeMenuStyles.Styles.itemStyle), props.itemStyle),
    iconName: "info-circle",
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.ABOUT,
        props: null
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 65,
      columnNumber: 9
    }
  }, "About")));
};

var _default = HomeMenu;
exports["default"] = _default;