"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _useResources2 = _interopRequireDefault(require("../hooks/useResources"));

var _application = require("../models/application");

var _Primitives = require("../primitives/Primitives");

var _ResourcesStyles = require("../styles/Resources/ResourcesStyles");

var _utils = require("../utils");

var _AppIcon = _interopRequireDefault(require("./AppIcon"));

var _AppText = _interopRequireDefault(require("./AppText"));

var _this = void 0,
    _jsxFileName = "/home/vsts/work/1/s/common/build/web/temp/components/Resources.tsx";

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var Resources = function Resources(props) {
  var source = {
    uri: 'http://samples.leanpub.com/thereactnativebook-sample.pdf',
    cache: true
  };

  var _useResources = (0, _useResources2["default"])(),
      currentChapter = _useResources.currentChapter,
      setCurrentChapterById = _useResources.setCurrentChapterById;

  var _useState = (0, _react.useState)([0]),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      chapterTrail = _useState2[0],
      setChapterTrail = _useState2[1];

  var _useState3 = (0, _react.useState)(1),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      currentPdfPageNumber = _useState4[0],
      setCurrentPdfPageNumber = _useState4[1];

  var _useState5 = (0, _react.useState)(undefined),
      _useState6 = (0, _slicedToArray2["default"])(_useState5, 2),
      pdf = _useState6[0],
      setPdf = _useState6[1];

  var handleItemPress = function handleItemPress(id) {
    var newTrail = [].concat((0, _toConsumableArray2["default"])(chapterTrail), [id]);
    setChapterTrail(newTrail);
    setCurrentChapterById(id);
  };

  var handleNextPdfPageClick = function handleNextPdfPageClick() {
    pdf.setPage(currentPdfPageNumber + 1);
    setCurrentPdfPageNumber(currentPdfPageNumber + 1);
  };

  var handleBackPdfPageClick = function handleBackPdfPageClick() {
    pdf.setPage(currentPdfPageNumber - 1);
    setCurrentPdfPageNumber(currentPdfPageNumber - 1);
  };

  var handleBackButton = function handleBackButton() {
    if (currentChapter && currentChapter.id == 0) {
      props.navigate({
        screen: _application.NavigationScreen.HOME,
        props: {}
      });
    }

    var newTrail = chapterTrail.slice(0, -1);
    setChapterTrail(newTrail);
    setCurrentChapterById(newTrail[newTrail.length - 1]);
  };

  (0, _react.useEffect)(function () {
    setCurrentChapterById(0);
  }, []);

  var renderResourcesTab = function renderResourcesTab(number, label) {
    return _react["default"].createElement(_Primitives.Box, {
      style: _ResourcesStyles.Styles.resourceTab,
      onPress: function onPress() {},
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 57,
        columnNumber: 7
      }
    }, _react["default"].createElement(_Primitives.Box, {
      style: _ResourcesStyles.Styles.resourceTabText,
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 58,
        columnNumber: 9
      }
    }, _react["default"].createElement(_Primitives.Box, {
      style: {
        paddingRight: 10
      },
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 59,
        columnNumber: 11
      }
    }, _react["default"].createElement(_AppText["default"], {
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 60,
        columnNumber: 13
      }
    }, number)), _react["default"].createElement(_Primitives.Box, {
      style: {},
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 62,
        columnNumber: 11
      }
    }, _react["default"].createElement(_AppText["default"], {
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 63,
        columnNumber: 13
      }
    }, label))), _react["default"].createElement(_Primitives.Box, {
      style: {
        alignSelf: "flex-end",
        position: "absolute"
      },
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 66,
        columnNumber: 9
      }
    }, _react["default"].createElement(_AppIcon["default"], {
      size: "xxl",
      name: "caret-right",
      color: _utils.Colors.PRIMARY_MEDIUM,
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 67,
        columnNumber: 11
      }
    })));
  };

  return _react["default"].createElement(_Primitives.Box, {
    style: _ResourcesStyles.Styles.container,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 77,
      columnNumber: 5
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _ResourcesStyles.Styles.body,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 78,
      columnNumber: 7
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _ResourcesStyles.Styles.titleSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 79,
      columnNumber: 9
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _ResourcesStyles.Styles.title,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 80,
      columnNumber: 11
    }
  }, "Resources")), currentChapter && _react["default"].createElement(_Primitives.Box, {
    style: _ResourcesStyles.Styles.section1,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 83,
      columnNumber: 11
    }
  }, currentChapter.possible_chapters && currentChapter.possible_chapters.map(function (item, i) {
    return _react["default"].createElement(_Primitives.TouchableBox, {
      key: i,
      onPress: function onPress() {
        return handleItemPress(item.id);
      },
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 87,
        columnNumber: 19
      }
    }, renderResourcesTab(item.index, item.title));
  })), currentChapter && !currentChapter.possible_chapters && _react["default"].createElement(_Primitives.Box, {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 97,
      columnNumber: 11
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _ResourcesStyles.Styles.pdfContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 98,
      columnNumber: 13
    }
  }, _react["default"].createElement(_Primitives.Link, {
    href: 'http://samples.leanpub.com/thereactnativebook-sample.pdf',
    fileName: "test.pdf",
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 99,
      columnNumber: 15
    }
  }, "Download PDF"))), _react["default"].createElement(_Primitives.TouchableBox, {
    onPress: handleBackButton,
    style: _ResourcesStyles.Styles.backButtonSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 106,
      columnNumber: 9
    }
  }, _react["default"].createElement(_AppIcon["default"], {
    size: "medium",
    name: "arrow-left",
    color: _utils.Colors.PRIMARY_MEDIUM,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 107,
      columnNumber: 11
    }
  }))));
};

var _default = Resources;
exports["default"] = _default;