"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.resetPassword = exports.editProfile = exports.logout = exports.renewToken = exports.loginUser = exports.getUser = void 0;

var _oidcClient = require("oidc-client");

var _utils = require("../utils");

var userManager = null;

var initUserManager = function initUserManager() {
  var settings = {
    authority: _utils.Constants.AUTHORITY_METADATA_ENDPOINT,
    loadUserInfo: true,
    redirect_uri: _utils.Constants.REDIRECT_URL,
    response_type: _utils.Constants.RESPONSE_TYPE,
    response_mode: _utils.Constants.RESPONSE_MODE,
    client_id: _utils.Constants.CLIENT_ID,
    grant_type: _utils.Constants.GRANT_TYPE,
    scope: _utils.Constants.SCOPE,
    editProfilePolicy: _utils.Constants.EDIT_PROFILE_POLICY,
    resetPasswordPolicy: _utils.Constants.RESET_PASSWORD_POLICY,
    post_logout_redirect_uri: _utils.Constants.POST_LOGOUT_REDIRECT_URL,
    tenantName: _utils.Constants.TENANT_NAME,
    metadata: {
      issuer: _utils.Constants.METADATA_ISSUER,
      authorization_endpoint: _utils.Constants.METADATA_AUTHORIZATION_ENDPOINT,
      token_endpoint: _utils.Constants.METADATA_TOKEN_ENDPOINT,
      end_session_endpoint: _utils.Constants.METADATA_END_SESSION_ENDPOINT,
      jwks_uri: _utils.Constants.METADATA_JWKS_URI
    }
  };
  userManager = new _oidcClient.UserManager(settings);
  _oidcClient.Log.logger = console;
  _oidcClient.Log.level = _oidcClient.Log.INFO;
};

var initUserManagerResetPassword = function initUserManagerResetPassword() {
  var settings = {
    authority: _utils.Constants.AUTHORITY_METADATA_ENDPOINT,
    loadUserInfo: true,
    redirect_uri: _utils.Constants.REDIRECT_URL,
    response_type: _utils.Constants.RESPONSE_TYPE,
    response_mode: _utils.Constants.RESPONSE_MODE,
    client_id: _utils.Constants.CLIENT_ID,
    grant_type: _utils.Constants.GRANT_TYPE,
    scope: _utils.Constants.SCOPE,
    editProfilePolicy: _utils.Constants.EDIT_PROFILE_POLICY,
    resetPasswordPolicy: _utils.Constants.RESET_PASSWORD_POLICY,
    post_logout_redirect_uri: _utils.Constants.POST_LOGOUT_REDIRECT_URL,
    tenantName: _utils.Constants.TENANT_NAME,
    metadata: {
      issuer: _utils.Constants.METADATA_ISSUER,
      authorization_endpoint: _utils.Constants.METADATA_AUTHORIZATION_ENDPOINT,
      token_endpoint: _utils.Constants.METADATA_TOKEN_ENDPOINT,
      end_session_endpoint: _utils.Constants.METADATA_END_SESSION_ENDPOINT,
      jwks_uri: _utils.Constants.METADATA_JWKS_URI
    }
  };
  userManager = new _oidcClient.UserManager(settings);
  _oidcClient.Log.logger = console;
  _oidcClient.Log.level = _oidcClient.Log.INFO;
};

var getUser = function getUser() {
  if (userManager == null) {
    initUserManager();
  }

  return userManager.getUser();
};

exports.getUser = getUser;

var loginUser = function loginUser() {
  if (userManager == null) {
    initUserManager();
  }

  return userManager.signinRedirect();
};

exports.loginUser = loginUser;

var renewToken = function renewToken() {
  if (userManager == null) {
    initUserManager();
  }

  return userManager.signinSilent();
};

exports.renewToken = renewToken;

var logout = function logout() {
  if (userManager == null) {
    initUserManager();
  }

  return userManager.signoutRedirect();
};

exports.logout = logout;

var editProfile = function editProfile() {
  if (userManager == null) {
    initUserManager();
  }

  return userManager.removeUser();
};

exports.editProfile = editProfile;

var resetPassword = function resetPassword() {
  if (userManager == null) {
    initUserManagerResetPassword();
  }

  return userManager.signinRedirect();
};

exports.resetPassword = resetPassword;