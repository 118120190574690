import React, { useEffect, useState } from 'react';
import { loginUser, Constants, getUser, Colors } from '@pairva/common/build/web/dist';
import { useHistory, Redirect } from 'react-router-dom';
const SignOutScreen = (props) => {
  const history = useHistory();
  const [user, setUser] = useState(null);
  useEffect(() => {
    localStorage.clear();
    window.location.reload();
  }, []);
  return (
    <Redirect to='/home'/>
  )

}

export default SignOutScreen;
