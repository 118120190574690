"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PRIMARY_LINEAR_END = exports.PRIMARY_LINEAR_START = exports.GRAY_SPACE = exports.GRAY_DARK = exports.GRAY_MEDIUM = exports.GRAY_LIGHT = exports.ALERT = exports.WARNING = exports.SUCCESS = exports.ORANGE = exports.BLACK = exports.WHITE_LIGHT = exports.WHITE = exports.SECONDARY = exports.PRIMARY_LIGHT = exports.PRIMARY_MEDIUM = exports.PRIMARY = void 0;
var PRIMARY = '#290F5C';
exports.PRIMARY = PRIMARY;
var PRIMARY_MEDIUM = '#290A66';
exports.PRIMARY_MEDIUM = PRIMARY_MEDIUM;
var PRIMARY_LIGHT = '#f5f0fa';
exports.PRIMARY_LIGHT = PRIMARY_LIGHT;
var SECONDARY = '#767676';
exports.SECONDARY = SECONDARY;
var WHITE = '#FFFFFF';
exports.WHITE = WHITE;
var WHITE_LIGHT = '#F7F5FA';
exports.WHITE_LIGHT = WHITE_LIGHT;
var BLACK = '#000000';
exports.BLACK = BLACK;
var ORANGE = '#F15A24';
exports.ORANGE = ORANGE;
var SUCCESS = '#3adb76';
exports.SUCCESS = SUCCESS;
var WARNING = '#ffae00';
exports.WARNING = WARNING;
var ALERT = '#cc4b37';
exports.ALERT = ALERT;
var GRAY_LIGHT = '#e6e6e6';
exports.GRAY_LIGHT = GRAY_LIGHT;
var GRAY_MEDIUM = '#cacaca';
exports.GRAY_MEDIUM = GRAY_MEDIUM;
var GRAY_DARK = '#333333';
exports.GRAY_DARK = GRAY_DARK;
var GRAY_SPACE = '#999999';
exports.GRAY_SPACE = GRAY_SPACE;
var PRIMARY_LINEAR_START = '#290F5C';
exports.PRIMARY_LINEAR_START = PRIMARY_LINEAR_START;
var PRIMARY_LINEAR_END = '#522295';
exports.PRIMARY_LINEAR_END = PRIMARY_LINEAR_END;