"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _Primitives = require("../primitives/Primitives");

var _SimpleNavigationMenuStyles = require("../styles/SimpleNavigationMenu/SimpleNavigationMenuStyles");

var _application = require("../models/application");

var _AppText = _interopRequireDefault(require("./AppText"));

var _utils = require("../utils");

var _Constants = require("../utils/Constants");

var _useLocalStorage5 = _interopRequireDefault(require("../hooks/useLocalStorage"));

var _this = void 0,
    _jsxFileName = "/home/vsts/work/1/s/common/build/web/temp/components/SimpleNavigationMenu.tsx";

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var SimpleNavigationMenu = function SimpleNavigationMenu(props) {
  console.log('from nav menu', props.user);

  var _useState = (0, _react.useState)(null),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      anchorEl = _useState2[0],
      setAnchorEl = _useState2[1];

  if (_Constants.IS_WEB) {
    var _useLocalStorage = (0, _useLocalStorage5["default"])("token", undefined),
        _useLocalStorage2 = (0, _slicedToArray2["default"])(_useLocalStorage, 2),
        token = _useLocalStorage2[0],
        setToken = _useLocalStorage2[1];

    var _useLocalStorage3 = (0, _useLocalStorage5["default"])("user", undefined),
        _useLocalStorage4 = (0, _slicedToArray2["default"])(_useLocalStorage3, 2),
        user = _useLocalStorage4[0],
        setUser = _useLocalStorage4[1];
  }

  return _react["default"].createElement(_Primitives.Box, {
    style: _objectSpread(_objectSpread({}, _SimpleNavigationMenuStyles.Styles.container), props.containerStyle),
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 30,
      columnNumber: 5
    }
  }, _react["default"].createElement(_Primitives.TouchableBox, {
    style: props.itemStyle,
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.CSA,
        props: null
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 31,
      columnNumber: 7
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _SimpleNavigationMenuStyles.Styles.menuOptionsText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 36,
      columnNumber: 9
    }
  }, _utils.Constants.IS_WEB ? 'Clinical Situations' : 'Clinical Situation Algorithms')), _react["default"].createElement(_Primitives.TouchableBox, {
    style: props.itemStyle,
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.VAS,
        props: null
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 42,
      columnNumber: 7
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _SimpleNavigationMenuStyles.Styles.menuOptionsText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 47,
      columnNumber: 9
    }
  }, _utils.Constants.IS_WEB ? 'VA Selection Assistant' : 'Vascular Access Selection Assistant')), _react["default"].createElement(_Primitives.TouchableBox, {
    style: props.itemStyle,
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.RESOURCES,
        props: null
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 53,
      columnNumber: 7
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _SimpleNavigationMenuStyles.Styles.menuOptionsText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 58,
      columnNumber: 9
    }
  }, "Resources")), props.user && _react["default"].createElement(_Primitives.TouchableBox, {
    style: props.itemStyle,
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.HISTORY,
        props: null
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 61,
      columnNumber: 7
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _SimpleNavigationMenuStyles.Styles.menuOptionsText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 66,
      columnNumber: 9
    }
  }, "History")), _react["default"].createElement(_Primitives.TouchableBox, {
    style: props.itemStyle,
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.ABOUT,
        props: null
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 68,
      columnNumber: 7
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _SimpleNavigationMenuStyles.Styles.menuOptionsText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 73,
      columnNumber: 9
    }
  }, "About")), props.user == null ? _react["default"].createElement(_Primitives.TouchableBox, {
    style: props.itemStyle,
    onPress: function onPress() {
      props.navigate({
        screen: _application.NavigationScreen.REGISTER,
        props: null
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 76,
      columnNumber: 9
    }
  }, _react["default"].createElement(_AppText["default"], {
    iconColor: _utils.Colors.WHITE_LIGHT,
    iconName: 'user-circle',
    containerStyle: {
      flexDirection: 'row',
      paddingRight: 0,
      marginRight: 0
    },
    textStyle: _SimpleNavigationMenuStyles.Styles.menuOptionsText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 82,
      columnNumber: 11
    }
  }, "Register / Login")) : _Constants.IS_WEB ? _react["default"].createElement(_Primitives.Box, {
    style: {
      display: 'flex'
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 96,
      columnNumber: 9
    }
  }, _react["default"].createElement(_AppText["default"], {
    iconColor: _utils.Colors.WHITE_LIGHT,
    iconName: 'user-circle',
    containerStyle: {
      flexDirection: 'row',
      paddingRight: 0,
      marginRight: 0
    },
    textStyle: _SimpleNavigationMenuStyles.Styles.menuOptionsText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 97,
      columnNumber: 11
    }
  }, props.user.name), _react["default"].createElement(_Primitives.TouchableBox, {
    style: props.itemStyle,
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.EDITPROFILE,
        props: null
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 109,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _SimpleNavigationMenuStyles.Styles.menuOptionsText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 114,
      columnNumber: 13
    }
  }, "Edit Profile")), _react["default"].createElement(_Primitives.TouchableBox, {
    style: props.itemStyle,
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.SIGNOUT,
        props: null
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 116,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    iconColor: _utils.Colors.WHITE_LIGHT,
    iconName: 'sign-out',
    textStyle: _SimpleNavigationMenuStyles.Styles.menuOptionsText,
    alignRight: true,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 119,
      columnNumber: 13
    }
  }, "Sign Out"))) : null);
};

var _default = SimpleNavigationMenu;
exports["default"] = _default;