import { INavigation, About, Constants } from "@pairva/common/build/web/dist";
import { useHistory } from "react-router-dom";
import React from "react";
import { onNavigate } from "../util/util";
import * as Colors from "@pairva/common/src/utils/Colors";

const AboutScreen = (props) => {
  const history = useHistory();

  const openUrl = () => {
    window.location.href = Constants.KIDNEY_CARE_URL;
  };

  const openKdoqiGuidelines = () => {
    window.location.href = Constants.KDOQI_GUIDELINES_URL;
  }

  const content = (
    <div style={styles.container}>
      <About
        onOpenUrl={openUrl}
        handleKdoqiGuidelines={openKdoqiGuidelines}
        kidneyCareLogo={
          <img
            style={{
              alignSelf: "center",
              width: 200,
              marginBottom: 20,
            }}
            src={require("../assets/logos/logo-KCNI_color.png").default}
          />
        }
      />
    </div>
  );

  return content;
};

export default AboutScreen;
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
    backgroundColor: Colors.WHITE_LIGHT
  },
  kidneyLogo: {
    flex: 1,
    width: 300,
    height: 100,
    resizeMode: "contain",
    marginBottom: 20,
  },
};
