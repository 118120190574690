"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styles = void 0;

var _utils = require("../../utils");

var Styles = {
  container: {
    flex: 1,
    display: 'flex'
  },
  menuOptionsText: {
    color: _utils.Colors.WHITE_LIGHT,
    fontSize: _utils.Spacing.FONT_SIZE_14,
    whiteSpace: 'nowrap'
  }
};
exports.Styles = Styles;