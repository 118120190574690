import {
  VascularAccessSelectionAssistant,
  INavigation,
  useLocalStorage
} from "@pairva/common/build/web/dist";
import { useHistory } from "react-router-dom";
import React, { useContext } from "react";
import { onNavigate } from "../util/util";
import NavBar from "../navigation/NavBar";
import Footer from "../components/Footer";
import { userContext } from "../context/userContext";
import * as Colors from "@pairva/common/src/utils/Colors";

const VascularAccessScreen = (props) => {
  const history = useHistory();
  const [user, setUser] = useLocalStorage('user', undefined);
  const content = (
    <div style={styles.container}>
      <VascularAccessSelectionAssistant
        navigate={(navigator: InstanceType<typeof INavigation>) =>
          onNavigate(history, navigator)
        }
        user={user}
        onNextClick={() => {}}
      />
    </div>
  );

  return content;
};

export default VascularAccessScreen;

const styles = {
  container: {
    paddingLeft: "7%",
    paddingRight: "7%",
    width: "100%",
    background: Colors.WHITE_LIGHT
  },
};
