import {
  INavigation,
  VascularAccessResults,
  useLocalStorage,
  VasApi,
  useApi,
} from "@pairva/common/build/web/dist";
import { useHistory } from "react-router-dom";
import React, { useContext, useEffect, useState } from "react";
import { onNavigate } from "../util/util";
import { getValidToken } from "../util/tokenHandler";
var Loader = require('react-loader');

const VascularAccessResultsScreen = (props) => {
  const history = useHistory();
  const [user, setUser] = useLocalStorage('user', undefined);
  const [token, setToken] = useLocalStorage("authToken");
  const [isLoading, setIsLoading] = useState(true);
  const [resultsPdfUrl, setResultsPdfUrl] = useState('');
  const [pdfClicked, setPdfClicked] = useState(false);


  const loadInBrowser = () => {
    if (resultsPdfUrl.length > 0) {
      window.open(resultsPdfUrl);
    }
    else {
      setIsLoading(true);
      setPdfClicked(true);
    }
  };


  const getToken = async (): Promise<string> => {
    try {
      const validToken = await getValidToken(token);
      if (validToken) {
        setToken(validToken);
        return 'Bearer ' + validToken.access_token;
      }
      else {
        setToken(undefined);
        return token;
      }
    }
    catch (error) {
      setToken(undefined);
      return token;
    }
  }


  useEffect(() => {
    if (resultsPdfUrl.length > 0) {
      setIsLoading(false);
      if (pdfClicked) {
        loadInBrowser();
      }
    }
  }, [isLoading, resultsPdfUrl]);

  useEffect(() => {
    getToken();
  }, []);

  const content = (
    <div style={styles.container}>
      <VascularAccessResults
        vasId={props.location.state.vasId}
        resultsDict={props.location.state.resultsDict}
        patientSummary={props.location.state.patientSummary}
        navigate={(navigator: InstanceType<typeof INavigation>) =>
          onNavigate(history, navigator)
        }
        handlePdfClick={loadInBrowser}
        user={user}
        choicesDtoObj={props.location.state.choicesDtoObj}
        resultsDtoObj={props.location.state.resultsDtoObj}
        retrievedPdfLink={(pdfUrl) => { setIsLoading(false); setResultsPdfUrl(pdfUrl); }}
      />
    </div>
  );

  return content;
};

export default VascularAccessResultsScreen;
const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
};
