"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _react = _interopRequireDefault(require("react"));

var _Primitives = require("../primitives/Primitives");

var _AppStyles = require("../styles/AppStyles");

var _this = void 0,
    _jsxFileName = "/home/vsts/work/1/s/common/build/web/temp/components/AppNavigationHeader.tsx";

var AppHeader = function AppHeader(_ref) {
  var onLogoPress = _ref.onLogoPress,
      logo = _ref.logo,
      style = _ref.style;
  return _react["default"].createElement(_Primitives.Box, {
    style: _AppStyles.AppNavigationHeaderStyles.container,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 7,
      columnNumber: 5
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _AppStyles.AppNavigationHeaderStyles.topButtons,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 8,
      columnNumber: 7
    }
  }, _react["default"].createElement(_Primitives.TouchableBox, {
    onPress: function onPress() {
      return onLogoPress();
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 9,
      columnNumber: 9
    }
  }, logo)));
};

var _default = AppHeader;
exports["default"] = _default;