"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styles = void 0;

var _utils = require("../../utils");

var Styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 12,
    paddingRight: 12
  },
  title: {
    fontSize: _utils.Spacing.FONT_SIZE_24
  },
  titleSection: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20
  },
  paragraph: {
    marginTop: 10,
    marginBottom: 10
  },
  section1: {
    paddingBottom: 10,
    borderWidth: 0,
    borderStyle: 'solid',
    borderBottomWidth: 1,
    borderBottomColor: _utils.Colors.BLACK,
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  section2: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  kidneyLogo: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    width: 300,
    height: 100,
    resizeMode: 'contain',
    marginBottom: 20
  },
  kidneyCareLogoText: {
    fontWeight: 'bold',
    marginBottom: 20
  },
  linkText: {
    color: _utils.Colors.PRIMARY_MEDIUM
  },
  linkContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: 20,
    marginBottom: 20,
    cursor: 'pointer'
  }
};
exports.Styles = Styles;