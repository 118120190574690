"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FONT_BOLD = exports.FONT_REGULAR = exports.LINE_HEIGHT_16 = exports.LINE_HEIGHT_20 = exports.LINE_HEIGHT_24 = exports.FONT_SIZE_12 = exports.FONT_SIZE_14 = exports.FONT_SIZE_16 = exports.FONT_SIZE_20 = exports.FONT_SIZE_24 = exports.FONT_WEIGHT_BOLD = exports.FONT_WEIGHT_REGULAR = exports.FONT_FAMILY_BOLD = exports.FONT_FAMILY_REGULAR = void 0;

var _Display = require("./Display");

var FONT_FAMILY_REGULAR = 'System';
exports.FONT_FAMILY_REGULAR = FONT_FAMILY_REGULAR;
var FONT_FAMILY_BOLD = 'OpenSans-Bold';
exports.FONT_FAMILY_BOLD = FONT_FAMILY_BOLD;
var FONT_WEIGHT_REGULAR = '400';
exports.FONT_WEIGHT_REGULAR = FONT_WEIGHT_REGULAR;
var FONT_WEIGHT_BOLD = '700';
exports.FONT_WEIGHT_BOLD = FONT_WEIGHT_BOLD;
var FONT_SIZE_24 = (0, _Display.scaleFont)(24);
exports.FONT_SIZE_24 = FONT_SIZE_24;
var FONT_SIZE_20 = (0, _Display.scaleFont)(20);
exports.FONT_SIZE_20 = FONT_SIZE_20;
var FONT_SIZE_16 = (0, _Display.scaleFont)(16);
exports.FONT_SIZE_16 = FONT_SIZE_16;
var FONT_SIZE_14 = (0, _Display.scaleFont)(14);
exports.FONT_SIZE_14 = FONT_SIZE_14;
var FONT_SIZE_12 = (0, _Display.scaleFont)(12);
exports.FONT_SIZE_12 = FONT_SIZE_12;
var LINE_HEIGHT_24 = (0, _Display.scaleFont)(24);
exports.LINE_HEIGHT_24 = LINE_HEIGHT_24;
var LINE_HEIGHT_20 = (0, _Display.scaleFont)(20);
exports.LINE_HEIGHT_20 = LINE_HEIGHT_20;
var LINE_HEIGHT_16 = (0, _Display.scaleFont)(16);
exports.LINE_HEIGHT_16 = LINE_HEIGHT_16;
var FONT_REGULAR = {
  fontFamily: FONT_FAMILY_REGULAR,
  fontWeight: FONT_WEIGHT_REGULAR
};
exports.FONT_REGULAR = FONT_REGULAR;
var FONT_BOLD = {
  fontFamily: FONT_FAMILY_BOLD,
  fontWeight: FONT_WEIGHT_BOLD
};
exports.FONT_BOLD = FONT_BOLD;