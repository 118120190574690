import { INavigation, NavigationScreen } from "@pairva/common/build/web/dist";

export const onNavigate = (
  navigation: any,
  navigator: InstanceType<typeof INavigation>
) => {
  switch (navigator.screen) {
    case NavigationScreen.HOME:
      navigation.push("/home");
      break;
    case NavigationScreen.VAS:
      navigation.push("/vascularaccessscreen", navigator.props);
      break;
    case NavigationScreen.VASRESULTS:
      navigation.push("/vascularaccessresultsscreen", navigator.props);
      break;
    case NavigationScreen.CSA:
      navigation.push("/clinicalsituationshomescreen", navigator.props);
      break;
    case NavigationScreen.CSAFLOW:
      navigation.push("/clinicalsituationsflowscreen", navigator.props);
      break;
    case NavigationScreen.ABOUT:
      navigation.push("/aboutscreen", navigator.props);
      break;
    case NavigationScreen.RESOURCES:
      navigation.push("/resourcesscreen", navigator.props);
      break;
    case NavigationScreen.HISTORY:
      navigation.push('/historyscreen', navigator.props);
      break;
    case NavigationScreen.REGISTER:
      navigation.push('/register', navigator.props);
      break;
    case NavigationScreen.EDITPROFILE:
      navigation.push('/editprofile', navigator.props);
      break;
    case NavigationScreen.SIGNOUT:
      navigation.push('/signout', navigator.props);
      break;
    default:
      alert("implement navigation for " + JSON.stringify(navigator));
      break;
  }
};
