"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _Primitives = require("../primitives/Primitives");

var _ClinicalSituationsFlowStyles = require("../styles/ClinicalSituationsFlow/ClinicalSituationsFlowStyles");

var _application = require("../models/application");

var _AppText = _interopRequireDefault(require("./AppText"));

var _AppIcon = _interopRequireDefault(require("./AppIcon"));

var _utils = require("../utils");

var _useClinicalSituations = _interopRequireDefault(require("../hooks/useClinicalSituations"));

var _AppButton = _interopRequireDefault(require("./AppButton"));

var _Constants = require("../utils/Constants");

var _this = void 0,
    _jsxFileName = "/home/vsts/work/1/s/common/build/web/temp/components/ClinicalSituationsFlow.tsx";

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var ClinicalSituationsFlow = function ClinicalSituationsFlow(props) {
  var algorithmName = props.algorithmName;

  var _useState = (0, _react.useState)([1]),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      chapterTrail = _useState2[0],
      setChapterTrail = _useState2[1];

  var _useState3 = (0, _react.useState)(-1),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      radioButtonValue = _useState4[0],
      setRadioButtonValue = _useState4[1];

  var _useState5 = (0, _react.useState)(''),
      _useState6 = (0, _slicedToArray2["default"])(_useState5, 2),
      algTitle = _useState6[0],
      setAlgTitle = _useState6[1];

  var _useClinicalSituation = (0, _useClinicalSituations["default"])(algorithmName),
      currentChapter = _useClinicalSituation.currentChapter,
      setCurrentChapterById = _useClinicalSituation.setCurrentChapterById;

  (0, _react.useEffect)(function () {
    setCurrentChapterById(1);
  }, []);
  (0, _react.useEffect)(function () {
    if (currentChapter != undefined) {
      if (currentChapter.chapter == 1) {
        setAlgTitle(currentChapter.title);
      }
    }
  }, [currentChapter]);

  var handleBackButtonClick = function handleBackButtonClick() {
    if (currentChapter.chapter == 1) {
      props.navigate({
        screen: _application.NavigationScreen.CSA,
        props: {
          algorithmName: algorithmName
        }
      });
    } else {
      var newTrail = chapterTrail.slice(0, -1);
      setChapterTrail(newTrail);
      setCurrentChapterById(newTrail[newTrail.length - 1]);
      setRadioButtonValue(currentChapter.value);
    }
  };

  var rb = radioButtonValue;

  var handleNextButtonClick = function handleNextButtonClick() {
    var newChapter = currentChapter.possible_chapters.find(function (entry) {
      return entry.value == radioButtonValue;
    });

    if (newChapter != undefined) {
      if (newChapter.goto_chapter != undefined) {
        var newTrail = [].concat((0, _toConsumableArray2["default"])(chapterTrail), [newChapter.goto_chapter]);
        setChapterTrail(newTrail);
        setCurrentChapterById(newChapter.goto_chapter);
      } else {
        var _newTrail = [].concat((0, _toConsumableArray2["default"])(chapterTrail), [newChapter.chapter]);

        setChapterTrail(_newTrail);
        setCurrentChapterById(newChapter.chapter);
      }

      setRadioButtonValue(-1);
    }
  };

  var handleRadioButtonClick = function handleRadioButtonClick(event) {
    if (event != undefined) {
      setRadioButtonValue(event.value);
    }
  };

  return _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.container,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 90,
      columnNumber: 5
    }
  }, currentChapter && _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.body,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 92,
      columnNumber: 9
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.title,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 93,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _ClinicalSituationsFlowStyles.Styles.titleText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 94,
      columnNumber: 13
    }
  }, algTitle)), currentChapter.name == 'TRANSPLANT_FOR_HEMO' && _Constants.IS_WEB && _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.subTitleSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 97,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _ClinicalSituationsFlowStyles.Styles.subTitleText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 98,
      columnNumber: 15
    }
  }, "When eGFR ", '<', " 30 mL/min/1.73 m", _react["default"].createElement("sup", {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 99,
      columnNumber: 49
    }
  }, "2"), ", transplant patient should be referred to nephrologist for pre-KRT planning.")), currentChapter.name == 'TRANSPLANT_FOR_HEMO' && !_Constants.IS_WEB && _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.subTitleSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 106,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _ClinicalSituationsFlowStyles.Styles.subTitleText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 107,
      columnNumber: 15
    }
  }, "When eGFR ", '<', " 30 mL/min/1.73 m", _react["default"].createElement(_AppText["default"], {
    textStyle: _ClinicalSituationsFlowStyles.Styles.subTitleTextSmall,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 109,
      columnNumber: 17
    }
  }, "2"), ", transplant patient should be referred to nephrologist for pre-KRT planning.")), currentChapter.outcome && _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.outcomeSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 116,
      columnNumber: 13
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.outcomeIcon,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 117,
      columnNumber: 15
    }
  }, _react["default"].createElement(_AppIcon["default"], {
    size: "xxl",
    name: "stethoscope",
    color: _utils.Colors.PRIMARY_MEDIUM,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 118,
      columnNumber: 17
    }
  })), _react["default"].createElement(_AppText["default"], {
    textStyle: _ClinicalSituationsFlowStyles.Styles.outcomeLabelText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 124,
      columnNumber: 15
    }
  }, "Clinical Situation Outcome"), _react["default"].createElement(_AppText["default"], {
    textStyle: _ClinicalSituationsFlowStyles.Styles.outcome,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 125,
      columnNumber: 15
    }
  }, currentChapter.outcome)), currentChapter.follow_up && _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.followUp,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 129,
      columnNumber: 13
    }
  }, _react["default"].createElement(_Primitives.TouchableBox, {
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.VAS,
        props: {}
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 130,
      columnNumber: 15
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.followUpTextSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 134,
      columnNumber: 17
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _ClinicalSituationsFlowStyles.Styles.followUpText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 135,
      columnNumber: 19
    }
  }, "View Appropriateness Rankings"), _react["default"].createElement(_AppIcon["default"], {
    size: "large",
    name: "arrow-right",
    color: _utils.Colors.PRIMARY_MEDIUM,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 138,
      columnNumber: 19
    }
  })), _react["default"].createElement(_AppText["default"], {
    textStyle: _ClinicalSituationsFlowStyles.Styles.followUpSubText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 143,
      columnNumber: 17
    }
  }, "for various vascular access types"))), currentChapter.question && _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.questionSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 150,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _ClinicalSituationsFlowStyles.Styles.questionText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 151,
      columnNumber: 15
    }
  }, currentChapter.question), _react["default"].createElement(_Primitives.RadioButton, {
    deactiveColor: _utils.Colors.PRIMARY_MEDIUM,
    box: false,
    style: _ClinicalSituationsFlowStyles.Styles.option,
    options: currentChapter.possible_chapters,
    activeColor: _utils.Colors.PRIMARY_MEDIUM,
    onSelected: function onSelected(e) {
      handleRadioButtonClick(e);
    },
    paddingSize: 0,
    value: radioButtonValue,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 154,
      columnNumber: 15
    }
  })), currentChapter.notes && _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.notesSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 169,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _ClinicalSituationsFlowStyles.Styles.notesLabelText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 170,
      columnNumber: 15
    }
  }, "Notes:"), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 171,
      columnNumber: 15
    }
  }, currentChapter.notes)), currentChapter.next_steps && _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.nextStepsSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 175,
      columnNumber: 13
    }
  }, _react["default"].createElement(_Primitives.TouchableBox, {
    style: _ClinicalSituationsFlowStyles.Styles.moreAboutProcessSection,
    onPress: function onPress() {
      props.navigate({
        screen: _application.NavigationScreen.ABOUT,
        props: {}
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 176,
      columnNumber: 15
    }
  }, _react["default"].createElement(_AppText["default"], {
    iconSize: "medium",
    iconName: "angle-double-right",
    iconColor: _utils.Colors.ORANGE,
    textStyle: {
      color: _utils.Colors.ORANGE
    },
    alignRight: true,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 177,
      columnNumber: 17
    }
  }, "More about our process", ' ')), _react["default"].createElement(_AppText["default"], {
    textStyle: _ClinicalSituationsFlowStyles.Styles.nextStepsLabelText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 186,
      columnNumber: 15
    }
  }, "Next Step"), _react["default"].createElement(_Primitives.TouchableBox, {
    style: _ClinicalSituationsFlowStyles.Styles.nextStepContainer,
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.CSA,
        props: {}
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 187,
      columnNumber: 15
    }
  }, _react["default"].createElement(_AppIcon["default"], {
    size: "xxl",
    name: "stethoscope",
    color: _utils.Colors.PRIMARY_MEDIUM,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 192,
      columnNumber: 17
    }
  }), _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.followUpContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 196,
      columnNumber: 17
    }
  }, _react["default"].createElement(_AppText["default"], {
    iconSize: "large",
    iconName: "arrow-right",
    iconColor: _utils.Colors.PRIMARY_MEDIUM,
    alignRight: true,
    textStyle: _ClinicalSituationsFlowStyles.Styles.followUpText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 197,
      columnNumber: 19
    }
  }, "View other Situation Algorithms"))), _react["default"].createElement(_Primitives.TouchableBox, {
    style: _ClinicalSituationsFlowStyles.Styles.nextStepContainer,
    onPress: function onPress() {
      return props.navigate({
        screen: _application.NavigationScreen.VAS,
        props: {}
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 207,
      columnNumber: 15
    }
  }, _react["default"].createElement(_AppIcon["default"], {
    size: "xxl",
    name: "star-o",
    color: _utils.Colors.PRIMARY_MEDIUM,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 212,
      columnNumber: 17
    }
  }), _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.followUpContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 217,
      columnNumber: 17
    }
  }, _react["default"].createElement(_AppText["default"], {
    iconSize: "large",
    iconName: "arrow-right",
    iconColor: _utils.Colors.PRIMARY_MEDIUM,
    alignRight: true,
    textStyle: _ClinicalSituationsFlowStyles.Styles.followUpText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 218,
      columnNumber: 19
    }
  }, "View Appropriateness Rankings"), _react["default"].createElement(_AppText["default"], {
    textStyle: _ClinicalSituationsFlowStyles.Styles.nextStepDescription,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 226,
      columnNumber: 19
    }
  }, "for various vascular access types")))), _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.backAndNextSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 233,
      columnNumber: 11
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.backButtonSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 234,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppButton["default"], {
    onPress: handleBackButtonClick,
    containerStyle: _ClinicalSituationsFlowStyles.Styles.cancelButton,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 235,
      columnNumber: 15
    }
  }, _react["default"].createElement(_AppText["default"], {
    iconSize: "medium",
    iconName: "arrow-left",
    iconColor: _utils.Colors.PRIMARY_MEDIUM,
    textStyle: {
      color: _utils.Colors.PRIMARY_MEDIUM
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 238,
      columnNumber: 17
    }
  }, ' ', "Back"))), _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsFlowStyles.Styles.nextButtonSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 248,
      columnNumber: 13
    }
  }, currentChapter.possible_chapters && _react["default"].createElement(_AppButton["default"], {
    disabled: radioButtonValue == -1,
    containerStyle: _ClinicalSituationsFlowStyles.Styles.nextButton,
    onPress: handleNextButtonClick,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 250,
      columnNumber: 17
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: {
      color: _utils.Colors.WHITE_LIGHT
    },
    iconSize: "medium",
    iconName: "arrow-right",
    iconColor: _utils.Colors.WHITE_LIGHT,
    alignRight: true,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 254,
      columnNumber: 19
    }
  }, "Next"))))));
};

var _default = ClinicalSituationsFlow;
exports["default"] = _default;