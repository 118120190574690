import {
  INavigation,
  ClinicalSituationsFlow,
  Constants,
} from "@pairva/common/build/web/dist";
import { useHistory } from "react-router-dom";
import React from "react";
import { onNavigate } from "../util/util";

const ClinicalSituationsFlowScreen = (props) => {
  const history = useHistory();
  const content = (
    <div style={styles.container}>
      <ClinicalSituationsFlow
        algorithmName={props.location.state.algorithmName}
        navigate={(navigator: InstanceType<typeof INavigation>) =>
          onNavigate(history, navigator)
        }
      />
    </div>
  );

  return content;
};

export default ClinicalSituationsFlowScreen;

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
};
