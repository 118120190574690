"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _react = _interopRequireDefault(require("react"));

var _Primitives = require("../primitives/Primitives");

var _AboutStyles = require("../styles/About/AboutStyles");

var _AppText = _interopRequireDefault(require("./AppText"));

var _utils = require("../utils");

var _this = void 0,
    _jsxFileName = "/home/vsts/work/1/s/common/build/web/temp/components/About.tsx";

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var About = function About(props) {
  var handleWebsiteVisit = function handleWebsiteVisit() {
    props.onOpenUrl();
  };

  var handleKdoqiGuidelines = function handleKdoqiGuidelines() {
    props.onKdoqiGuidelines();
  };

  return _react["default"].createElement(_Primitives.Box, {
    style: _AboutStyles.Styles.container,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 27,
      columnNumber: 5
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _AboutStyles.Styles.titleSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 28,
      columnNumber: 7
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _objectSpread({}, _AboutStyles.Styles.title),
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 29,
      columnNumber: 9
    }
  }, "About")), _react["default"].createElement(_Primitives.Box, {
    style: _AboutStyles.Styles.section1,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 31,
      columnNumber: 7
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _AboutStyles.Styles.paragraph,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 32,
      columnNumber: 9
    }
  }, "My Vascular Access uses the KDOQI Clinical Practice Guidelines For Vascular Access and patient-specific factors \u2014 such as vascular anatomy, age, and functional status \u2014 to analyze the most appropriate vascular access treatment for each patient."), _react["default"].createElement(_AppText["default"], {
    textStyle: _AboutStyles.Styles.paragraph,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 38,
      columnNumber: 9
    }
  }, "These recommendations are supported by a validated consensus process based on a large data set generated from a study published in April 2016 in the Journal of Vascular Surgery entitled \u201CEstablishing patient-specific criteria for selecting the optimal upper extremity vascular access procedure\u201D, and later validated in a retrospective study involving 205 patients who underwent vascular access placement."), _react["default"].createElement(_AppText["default"], {
    textStyle: _AboutStyles.Styles.paragraph,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 46,
      columnNumber: 9
    }
  }, "The data is based on best available scientific evidence and the knowledge and experience of an international team of vascular access experts comprised of surgeons, nephrologists, and interventionalists."), _react["default"].createElement(_Primitives.TouchableBox, {
    onPress: handleKdoqiGuidelines,
    style: _AboutStyles.Styles.linkContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 49,
      columnNumber: 9
    }
  }, _react["default"].createElement(_AppText["default"], {
    iconColor: _utils.Colors.PRIMARY_MEDIUM,
    iconName: 'arrow-right',
    iconSize: 'extraLarge',
    textStyle: _AboutStyles.Styles.linkText,
    alignRight: true,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 50,
      columnNumber: 11
    }
  }, "View KDOQI Guidelines", ' '))), _react["default"].createElement(_Primitives.Box, {
    style: _AboutStyles.Styles.section2,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 60,
      columnNumber: 7
    }
  }, props.kidneyCareLogo, _react["default"].createElement(_AppText["default"], {
    textStyle: _AboutStyles.Styles.kidneyCareLogoText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 62,
      columnNumber: 9
    }
  }, "Kidney CARE Network International"), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 65,
      columnNumber: 9
    }
  }, "Their mission is to improve the quality of life and health outcomes of people with chronic kidney disease (CKD) through collaborative activities, research, and education."), _react["default"].createElement(_Primitives.TouchableBox, {
    onPress: handleWebsiteVisit,
    style: _AboutStyles.Styles.linkContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 70,
      columnNumber: 9
    }
  }, _react["default"].createElement(_AppText["default"], {
    iconColor: _utils.Colors.PRIMARY_MEDIUM,
    iconName: 'arrow-right',
    iconSize: 'extraLarge',
    textStyle: _AboutStyles.Styles.linkText,
    alignRight: true,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 71,
      columnNumber: 11
    }
  }, "Visit Website", ' '))));
};

var _default = About;
exports["default"] = _default;