"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SCALE_8 = exports.SCALE_12 = exports.SCALE_16 = exports.SCALE_18 = void 0;

var _Display = require("./Display");

var SCALE_18 = (0, _Display.scaleSize)(18);
exports.SCALE_18 = SCALE_18;
var SCALE_16 = (0, _Display.scaleSize)(16);
exports.SCALE_16 = SCALE_16;
var SCALE_12 = (0, _Display.scaleSize)(12);
exports.SCALE_12 = SCALE_12;
var SCALE_8 = (0, _Display.scaleSize)(8);
exports.SCALE_8 = SCALE_8;