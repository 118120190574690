"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.PROD_NATIVE_SCOPE = exports.DEV_NATIVE_SCOPE = exports.PROD_TENANT_NAME = exports.TENANT_NAME = exports.SIGN_UP_SIGN_IN_POLICY = exports.RESET_PASSWORD_POLICY = exports.EDIT_PROFILE_POLICY = exports.SCOPE = exports.PROD_AZURE_APP_ID = exports.AZURE_APP_ID = exports.PROD_REDIRECT_URL_NATIVE = exports.REDIRECT_URL_NATIVE = exports.POST_LOGOUT_REDIRECT_URL = exports.REDIRECT_URL = exports.KDOQI_GUIDELINES_URL = exports.KIDNEY_CARE_URL = exports.IS_WEB = exports.API_BASE = void 0;
var API_BASE = 'https://pairva-service/api/';
exports.API_BASE = API_BASE;
var IS_WEB = process.browser;
exports.IS_WEB = IS_WEB;
var KIDNEY_CARE_URL = 'https://www.kidneycarenetwork.ca/';
exports.KIDNEY_CARE_URL = KIDNEY_CARE_URL;
var KDOQI_GUIDELINES_URL = 'https://www.ajkd.org/action/showPdf?pii=S0272-6386%2819%2931137-0';
exports.KDOQI_GUIDELINES_URL = KDOQI_GUIDELINES_URL;
var REDIRECT_URL = "";
exports.REDIRECT_URL = REDIRECT_URL;
var POST_LOGOUT_REDIRECT_URL = "";
exports.POST_LOGOUT_REDIRECT_URL = POST_LOGOUT_REDIRECT_URL;
var REDIRECT_URL_NATIVE = "https://pairva.b2clogin.com/oauth2/nativeclient";
exports.REDIRECT_URL_NATIVE = REDIRECT_URL_NATIVE;
var PROD_REDIRECT_URL_NATIVE = "https://pairvaprod.b2clogin.com/oauth2/nativeclient";
exports.PROD_REDIRECT_URL_NATIVE = PROD_REDIRECT_URL_NATIVE;
var AZURE_APP_ID = "e39f91ae-6514-48b9-a942-e340174a3cac";
exports.AZURE_APP_ID = AZURE_APP_ID;
var PROD_AZURE_APP_ID = "a57ce1e2-72ca-42c4-9489-e4e385e074e9";
exports.PROD_AZURE_APP_ID = PROD_AZURE_APP_ID;
var SCOPE = "openid a9c36207-fcf1-4b24-9913-72f92cb517c5";
exports.SCOPE = SCOPE;
var EDIT_PROFILE_POLICY = "B2C_1_EditProfile";
exports.EDIT_PROFILE_POLICY = EDIT_PROFILE_POLICY;
var RESET_PASSWORD_POLICY = "B2C_1_PasswordReset";
exports.RESET_PASSWORD_POLICY = RESET_PASSWORD_POLICY;
var SIGN_UP_SIGN_IN_POLICY = "B2C_1_SignUpSignIn";
exports.SIGN_UP_SIGN_IN_POLICY = SIGN_UP_SIGN_IN_POLICY;
var TENANT_NAME = "PAIRVA";
exports.TENANT_NAME = TENANT_NAME;
var PROD_TENANT_NAME = "pairvaprod";
exports.PROD_TENANT_NAME = PROD_TENANT_NAME;
var DEV_NATIVE_SCOPE = "https://PAIRVA.onmicrosoft.com/8e50ec42-acb0-45b6-919c-67362abb11bf/Files.Read openid offline_access";
exports.DEV_NATIVE_SCOPE = DEV_NATIVE_SCOPE;
var PROD_NATIVE_SCOPE = "https://pairvaprod.onmicrosoft.com/9ade17e7-facf-46ce-a8fb-2f1b36bdf586/Files.Read openid offline_access";
exports.PROD_NATIVE_SCOPE = PROD_NATIVE_SCOPE;