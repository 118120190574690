"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styles = void 0;

var _utils = require("../../utils");

var Styles = {
  container: {
    display: 'flex',
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "3%",
    flexDirection: 'column'
  },
  subTitle: {
    color: _utils.Colors.WHITE_LIGHT,
    fontWeight: "bold",
    width: 300,
    textAlign: "center"
  }
};
exports.Styles = Styles;