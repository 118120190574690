import {
  INavigation,
  ClinicalSituationsHome,
  Constants,
} from "@pairva/common/build/web/dist";
import { useHistory } from "react-router-dom";
import React from "react";
import { onNavigate } from "../util/util";

const ClinicalSituationsHomeScreen = (props) => {
  const history = useHistory();
  const content = (
    <div style={styles.container}>
      <ClinicalSituationsHome
        navigate={(navigator: InstanceType<typeof INavigation>) =>
          onNavigate(history, navigator)
        }
        algorithmName={props.location.state ? props.location.state.algorithmName: null}
      />
    </div>
  );

  return content;
};

export default ClinicalSituationsHomeScreen;

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
};
