"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styles = void 0;

var _utils = require("../../utils");

var Styles = {
  footer: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: _utils.Colors.GRAY_DARK,
    padding: 0,
    margin: 0
  },
  footerInfoSection: {
    paddingTop: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column'
  },
  footerText2: {
    flex: 1,
    display: 'flex',
    color: _utils.Colors.WHITE_LIGHT,
    paddingTop: 8,
    textAlign: "center"
  },
  footerText: {
    paddingLeft: 6,
    paddingRight: 6,
    color: _utils.Colors.WHITE_LIGHT
  },
  termsOfUse: {
    display: 'flex',
    flexDirection: "row",
    justifyContent: "center",
    textAlign: 'center',
    alignItems: 'center',
    paddingTop: 8,
    paddingBottom: 8
  },
  footerTextButtonContainer: {
    width: 'auto'
  }
};
exports.Styles = Styles;