"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _apiClient = _interopRequireDefault(require("./apiClient"));

var choicesEndpoint = "/api/VascAssessmentChoices";
var resultsEndpoint = "/api/VascAssessmentResults";
var resourceEndpoint = "/api/Resource";
var resultPdfEndpoint = "/api/VascAssessmentResults/pdf";

var postVasChoices = function postVasChoices(choicesObj) {
  return _apiClient["default"].post("".concat(choicesEndpoint, "/add"), choicesObj);
};

var postVasResults = function postVasResults(vasResultsObj) {
  return _apiClient["default"].post("".concat(resultsEndpoint, "/add"), vasResultsObj);
};

var postResultsAndChoices = function postResultsAndChoices(choicesResults) {
  return _apiClient["default"].post("".concat(resultsEndpoint, "/addResultsAndChoices"), choicesResults);
};

var getVasChoices = function getVasChoices() {
  return _apiClient["default"].get("".concat(choicesEndpoint));
};

var getVasChoicesByVasId = function getVasChoicesByVasId(vasId) {
  return _apiClient["default"].get("".concat(choicesEndpoint, "/").concat(vasId));
};

var getVasResultsByVasId = function getVasResultsByVasId(vasId) {
  return _apiClient["default"].get("".concat(resultsEndpoint, "/").concat(vasId));
};

var getResourceBySection = function getResourceBySection(sectionId) {
  return _apiClient["default"].get("".concat(resourceEndpoint, "/section/").concat(sectionId));
};

var getResultPdf = function getResultPdf(resultsObj) {
  return _apiClient["default"].post("".concat(resultPdfEndpoint), resultsObj);
};

var getResultsPdfByVasId = function getResultsPdfByVasId(vasId) {
  return _apiClient["default"].get("".concat(resultPdfEndpoint, "/").concat(vasId));
};

var _default = {
  postVasChoices: postVasChoices,
  postVasResults: postVasResults,
  postResultsAndChoices: postResultsAndChoices,
  getVasChoices: getVasChoices,
  getVasChoicesByVasId: getVasChoicesByVasId,
  getVasResultsByVasId: getVasResultsByVasId,
  getResourceBySection: getResourceBySection,
  getResultPdf: getResultPdf,
  getResultsPdfByVasId: getResultsPdfByVasId,
  apiClient: _apiClient["default"]
};
exports["default"] = _default;