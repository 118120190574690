"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "loginUser", {
  enumerable: true,
  get: function get() {
    return AuthService.loginUser;
  }
});
Object.defineProperty(exports, "getUser", {
  enumerable: true,
  get: function get() {
    return AuthService.getUser;
  }
});
Object.defineProperty(exports, "renewToken", {
  enumerable: true,
  get: function get() {
    return AuthService.renewToken;
  }
});
Object.defineProperty(exports, "logout", {
  enumerable: true,
  get: function get() {
    return AuthService.logout;
  }
});
Object.defineProperty(exports, "editProfile", {
  enumerable: true,
  get: function get() {
    return AuthService.editProfile;
  }
});
Object.defineProperty(exports, "SimpleNavigationMenu", {
  enumerable: true,
  get: function get() {
    return _SimpleNavigationMenu["default"];
  }
});
Object.defineProperty(exports, "KidneyCareLogo", {
  enumerable: true,
  get: function get() {
    return _KidneyCareLogo["default"];
  }
});
Object.defineProperty(exports, "PairVaLogo", {
  enumerable: true,
  get: function get() {
    return _PairVaLogo["default"];
  }
});
Object.defineProperty(exports, "FooterInfo", {
  enumerable: true,
  get: function get() {
    return _FooterInfo["default"];
  }
});
Object.defineProperty(exports, "AppText", {
  enumerable: true,
  get: function get() {
    return _AppText["default"];
  }
});
Object.defineProperty(exports, "AppButton", {
  enumerable: true,
  get: function get() {
    return _AppButton["default"];
  }
});
Object.defineProperty(exports, "AppIcon", {
  enumerable: true,
  get: function get() {
    return _AppIcon["default"];
  }
});
Object.defineProperty(exports, "HomeMenu", {
  enumerable: true,
  get: function get() {
    return _HomeMenu["default"];
  }
});
Object.defineProperty(exports, "AppNavigationHeader", {
  enumerable: true,
  get: function get() {
    return _AppNavigationHeader["default"];
  }
});
Object.defineProperty(exports, "AppTab", {
  enumerable: true,
  get: function get() {
    return _AppTab["default"];
  }
});
Object.defineProperty(exports, "About", {
  enumerable: true,
  get: function get() {
    return _About["default"];
  }
});
Object.defineProperty(exports, "Resources", {
  enumerable: true,
  get: function get() {
    return _Resources["default"];
  }
});
Object.defineProperty(exports, "VascularAccessSelectionAssistant", {
  enumerable: true,
  get: function get() {
    return _VascularAccessSelectionAssistant["default"];
  }
});
Object.defineProperty(exports, "VascularAccessResults", {
  enumerable: true,
  get: function get() {
    return _VascularAccessResults["default"];
  }
});
Object.defineProperty(exports, "ClinicalSituationsFlow", {
  enumerable: true,
  get: function get() {
    return _ClinicalSituationsFlow["default"];
  }
});
Object.defineProperty(exports, "ClinicalSituationsHome", {
  enumerable: true,
  get: function get() {
    return _ClinicalSituationsHome["default"];
  }
});
Object.defineProperty(exports, "HistoryGrid", {
  enumerable: true,
  get: function get() {
    return _HistoryGrid["default"];
  }
});
Object.defineProperty(exports, "INavigation", {
  enumerable: true,
  get: function get() {
    return _application.INavigation;
  }
});
Object.defineProperty(exports, "ISurvey", {
  enumerable: true,
  get: function get() {
    return _application.ISurvey;
  }
});
Object.defineProperty(exports, "IContainer", {
  enumerable: true,
  get: function get() {
    return _application.IContainer;
  }
});
Object.defineProperty(exports, "NavigationScreen", {
  enumerable: true,
  get: function get() {
    return _application.NavigationScreen;
  }
});
Object.defineProperty(exports, "RESULT_TO_STARS", {
  enumerable: true,
  get: function get() {
    return _application.RESULT_TO_STARS;
  }
});
Object.defineProperty(exports, "ACCESS_POINT_DISPLAY", {
  enumerable: true,
  get: function get() {
    return _application.ACCESS_POINT_DISPLAY;
  }
});
Object.defineProperty(exports, "useResources", {
  enumerable: true,
  get: function get() {
    return _useResources["default"];
  }
});
Object.defineProperty(exports, "VasApi", {
  enumerable: true,
  get: function get() {
    return _VASApi["default"];
  }
});
Object.defineProperty(exports, "useApi", {
  enumerable: true,
  get: function get() {
    return _useApi["default"];
  }
});
Object.defineProperty(exports, "useLocalStorage", {
  enumerable: true,
  get: function get() {
    return _useLocalStorage["default"];
  }
});
Object.defineProperty(exports, "tokenContext", {
  enumerable: true,
  get: function get() {
    return _tokenContext["default"];
  }
});
exports.Context = exports.Typography = exports.Spacing = exports.Colors = exports.Constants = exports.AuthService = void 0;

var _react = _interopRequireDefault(require("react"));

var AuthService = _interopRequireWildcard(require("./services/Auth"));

exports.AuthService = AuthService;

var Constants = _interopRequireWildcard(require("./utils/Constants"));

exports.Constants = Constants;

var Colors = _interopRequireWildcard(require("./utils/Colors"));

exports.Colors = Colors;

var Spacing = _interopRequireWildcard(require("./utils/Spacing"));

exports.Spacing = Spacing;

var Typography = _interopRequireWildcard(require("./utils/Typography"));

exports.Typography = Typography;

var Context = _interopRequireWildcard(require("./context/AuthContext"));

exports.Context = Context;

var _SimpleNavigationMenu = _interopRequireDefault(require("./components/SimpleNavigationMenu"));

var _KidneyCareLogo = _interopRequireDefault(require("./components/KidneyCareLogo"));

var _PairVaLogo = _interopRequireDefault(require("./components/PairVaLogo"));

var _FooterInfo = _interopRequireDefault(require("./components/FooterInfo"));

var _AppText = _interopRequireDefault(require("./components/AppText"));

var _AppButton = _interopRequireDefault(require("./components/AppButton"));

var _AppIcon = _interopRequireDefault(require("./components/AppIcon"));

var _HomeMenu = _interopRequireDefault(require("./components/HomeMenu"));

var _AppNavigationHeader = _interopRequireDefault(require("./components/AppNavigationHeader"));

var _AppTab = _interopRequireDefault(require("./components/AppTab"));

var _About = _interopRequireDefault(require("./components/About"));

var _Resources = _interopRequireDefault(require("./components/Resources"));

var _VascularAccessSelectionAssistant = _interopRequireDefault(require("./components/VascularAccessSelectionAssistant"));

var _VascularAccessResults = _interopRequireDefault(require("./components/VascularAccessResults"));

var _ClinicalSituationsFlow = _interopRequireDefault(require("./components/ClinicalSituationsFlow"));

var _ClinicalSituationsHome = _interopRequireDefault(require("./components/ClinicalSituationsHome"));

var _HistoryGrid = _interopRequireDefault(require("./components/HistoryGrid"));

var _application = require("./models/application");

var _useResources = _interopRequireDefault(require("./hooks/useResources"));

var _VASApi = _interopRequireDefault(require("./api/VASApi"));

var _useApi = _interopRequireDefault(require("./hooks/useApi"));

var _useLocalStorage = _interopRequireDefault(require("./hooks/useLocalStorage"));

var _tokenContext = _interopRequireDefault(require("./context/tokenContext"));

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}