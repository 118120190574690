"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styles = void 0;

var _utils = require("../../utils");

var Styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    padding: 12,
    backgroundColor: _utils.Colors.WHITE_LIGHT
  },
  itemContainer: {
    flex: 1,
    display: 'flex',
    marginTop: 10,
    marginBottom: 10,
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  starsContainer: {
    flex: 1,
    display: 'flex'
  },
  starContainer: {
    marginTop: 10,
    marginBottom: 10
  },
  title: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    flexShrink: 1,
    alignItems: "center",
    marginTop: 10,
    marginBottom: 10
  },
  titleText: {
    fontSize: _utils.Spacing.FONT_SIZE_24,
    textAlign: "center"
  },
  moreInfoSection: {},
  moreInfoLabelSection: {
    display: 'flex',
    justifyContent: "center",
    marginTop: 20,
    marginBottom: 20,
    alignSelf: "center"
  },
  moreInfoLabelSectionText: {
    color: _utils.Colors.ORANGE
  },
  moreInfoContent: {
    marginBottom: 20,
    display: 'flex',
    flexDirection: "column"
  },
  moreInfoText: {
    paddingBottom: 20
  },
  moreInfoStarDescContainer: {},
  moreInfoStarDescText: {
    fontWeight: 'bold'
  },
  moreAboutProcessContainer: {
    display: 'flex',
    justifyContent: "center",
    marginBottom: 20,
    alignItems: "center",
    alignSelf: "center",
    marginTop: 20
  },
  moreAboutProcessText: {
    color: _utils.Colors.ORANGE
  },
  patientSummarySection: {
    marginBottom: 30,
    paddingBottom: 30,
    marginTop: 30,
    paddingTop: 30,
    borderStyle: 'solid',
    'border-width': '1px 0px 1px',
    borderBotttomWidth: 1,
    borderTopWidth: 1,
    borderTopColor: _utils.Colors.BLACK,
    borderBottomColor: _utils.Colors.BLACK,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  patientSummaryTitleText: {
    fontWeight: "bold"
  },
  patientSummaryTitle: {
    display: 'flex',
    alignItems: "center",
    marginBottom: 20
  },
  summaryLine: {
    display: 'flex',
    marginBottom: 10
  },
  summarySlot: {
    fontWeight: "bold",
    marginRight: 5
  },
  summarySection: {},
  footer: {
    marginLeft: 100,
    marginRight: 100
  },
  footerButtonContainer: {
    height: 250,
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center'
  },
  footerButtons: {
    color: _utils.Colors.PRIMARY_MEDIUM,
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  footerButtonText: {
    color: _utils.Colors.PRIMARY_MEDIUM,
    fontSize: 18,
    fontWeight: 'bold',
    margin: 5
  }
};
exports.Styles = Styles;