export var PRIMARY = '#290F5C';
export var PRIMARY_MEDIUM = '#290A66';
export var PRIMARY_LIGHT = '#f5f0fa';
export var SECONDARY = '#767676';
export var WHITE = '#FFFFFF';
export var WHITE_LIGHT = '#F7F5FA';
export var BLACK = '#000000';
export var ORANGE = '#F15A24'; // ACTIONS

export var SUCCESS = '#3adb76';
export var WARNING = '#ffae00';
export var ALERT = '#cc4b37'; // GRAYSCALE

export var GRAY_LIGHT = '#e6e6e6';
export var GRAY_MEDIUM = '#cacaca';
export var GRAY_DARK = '#333333';
export var GRAY_SPACE = '#999999'; // GRADIENTS

export var PRIMARY_LINEAR_START = '#290F5C';
export var PRIMARY_LINEAR_END = '#522295';