"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _Primitives = require("../primitives/Primitives");

var _VascularAccessResultsStyles = require("../styles/VascularAccessResults/VascularAccessResultsStyles");

var _application = require("../models/application");

var _AppText = _interopRequireDefault(require("./AppText"));

var _AppIcon = _interopRequireDefault(require("./AppIcon"));

var _utils = require("../utils");

var _useApi5 = _interopRequireDefault(require("./../hooks/useApi"));

var _VASApi = _interopRequireDefault(require("./../api/VASApi"));

var _VascularAccessSelectionService = _interopRequireDefault(require("../services/VascularAccessSelectionService"));

var _tokenContext = _interopRequireDefault(require("../context/tokenContext"));

var _Constants = require("../utils/Constants");

var _useLocalStorage3 = _interopRequireDefault(require("../hooks/useLocalStorage"));

var _this = void 0,
    _jsxFileName = "/home/vsts/work/1/s/common/build/web/temp/components/VascularAccessResults.tsx";

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var VascularAccessResults = function VascularAccessResults(props) {
  var _useState = (0, _react.useState)(false),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      showMoreInfo = _useState2[0],
      setShowMoreInfo = _useState2[1];

  var _useState3 = (0, _react.useState)([]),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      sortedEntries = _useState4[0],
      setSortedEntries = _useState4[1];

  var _useState5 = (0, _react.useState)({}),
      _useState6 = (0, _slicedToArray2["default"])(_useState5, 2),
      patientSummary = _useState6[0],
      setPatientSummary = _useState6[1];

  var authToken = null;

  if (_Constants.IS_WEB) {
    var _useLocalStorage = (0, _useLocalStorage3["default"])("authToken"),
        _useLocalStorage2 = (0, _slicedToArray2["default"])(_useLocalStorage, 2),
        token = _useLocalStorage2[0],
        setToken = _useLocalStorage2[1];

    if (token) {
      authToken = 'Bearer ' + token.access_token;
    } else {
      authToken = '';
    }
  } else {
    var _useContext = (0, _react.useContext)(_tokenContext["default"]),
        _token = _useContext.token,
        _setToken = _useContext.setToken;

    authToken = _token;
  }

  var _useApi = (0, _useApi5["default"])(_VASApi["default"].getVasChoicesByVasId),
      getVasChoicesRequest = _useApi.request;

  var _useApi2 = (0, _useApi5["default"])(_VASApi["default"].postResultsAndChoices),
      saveResultsError = _useApi2.error,
      saveResultsLoading = _useApi2.loading,
      saveResultsRequest = _useApi2.request;

  var _useApi3 = (0, _useApi5["default"])(_VASApi["default"].getResultsPdfByVasId),
      getSavedPdfError = _useApi3.error,
      getSavedPdfLoading = _useApi3.loading,
      getSavedPdfRequest = _useApi3.request;

  var _useApi4 = (0, _useApi5["default"])(_VASApi["default"].getResultPdf),
      getResultsPdfError = _useApi4.error,
      getResultsPdfLoading = _useApi4.loading,
      getResultsPdf = _useApi4.request;

  var pdfLoaded = function pdfLoaded() {
    return !getSavedPdfLoading && !getSavedPdfError && !saveResultsLoading && !saveResultsError && !getResultsPdfLoading && !getResultsPdfError;
  };

  (0, _react.useEffect)(function () {
    if (props.resultsDict) {
      var entries = Object.entries(props.resultsDict);
      var tempSortedEntries = entries.sort(function (a, b) {
        return b[1] - a[1];
      });
      var tempPatientSummary = {};
      tempPatientSummary['age'] = props.patientSummary.age;
      tempPatientSummary['dialysis'] = props.patientSummary.dialysis;
      tempPatientSummary['facility'] = props.patientSummary.facility;
      tempPatientSummary['bmi'] = props.patientSummary.bmi;
      tempPatientSummary['cephalic_vein'] = props.patientSummary.cephalic_vein;
      tempPatientSummary['basilic_vein'] = props.patientSummary.basilic_vein;
      tempPatientSummary['artery'] = props.patientSummary.artery;

      if (props.user) {
        saveResultsAndGetPDF(tempPatientSummary);
      } else {
        callForResultsPdf();
      }

      setPatientSummary(tempPatientSummary);
      setSortedEntries(tempSortedEntries);
    } else if (props.vasId) {
      loadVasData();
    }
  }, []);

  var saveResultsAndGetPDF = function () {
    var _ref = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee(tempPatientSummary) {
      var requestObj, response;
      return _regenerator["default"].wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              _context.prev = 0;

              _VASApi["default"].apiClient.setHeader('Authorization', "".concat(authToken));

              requestObj = {
                vascassessmentresults: props.resultsDtoObj,
                vascassessmentchoices: props.choicesDtoObj
              };
              console.log('RequestObj: ', requestObj);
              _context.next = 6;
              return saveResultsRequest(requestObj);

            case 6:
              response = _context.sent;
              parseResultsResponse(response.data, tempPatientSummary);
              _context.next = 13;
              break;

            case 10:
              _context.prev = 10;
              _context.t0 = _context["catch"](0);
              console.log('error saving vas results', _context.t0);

            case 13:
            case "end":
              return _context.stop();
          }
        }
      }, _callee, null, [[0, 10]]);
    }));

    return function saveResultsAndGetPDF(_x) {
      return _ref.apply(this, arguments);
    };
  }();

  var parseResultsResponse = function parseResultsResponse(dataString, tempPatientSummary) {
    try {
      var objectIndex = dataString.indexOf('},{');
      var vasObjString = dataString.substring(1, objectIndex + 1);
      var vasObject = JSON.parse(vasObjString);
      console.log('VAS Object: ', vasObject);
      var pdfObjString = dataString.substring(objectIndex + 2, dataString.length - 1);
      var pdfObject = JSON.parse(pdfObjString);
      console.log('Second Object: ', pdfObject);
      tempPatientSummary['vas_id'] = vasObject.vasId;
      props.resultsDtoObj['VasId'] = vasObject.vasId;
      props.retrievedPdfLink(pdfObject.URI);
    } catch (error) {
      console.log('Error Parsing Response: ', error);
    }
  };

  var getSavedResultsPdf = function () {
    var _ref2 = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee2(retrieveVasId) {
      var response, pdfUrl;
      return _regenerator["default"].wrap(function _callee2$(_context2) {
        while (1) {
          switch (_context2.prev = _context2.next) {
            case 0:
              _context2.prev = 0;

              _VASApi["default"].apiClient.setHeader('Authorization', "".concat(authToken));

              _context2.next = 4;
              return getSavedPdfRequest(retrieveVasId);

            case 4:
              response = _context2.sent;
              pdfUrl = JSON.parse(response.data).URI;
              props.retrievedPdfLink(pdfUrl);
              _context2.next = 12;
              break;

            case 9:
              _context2.prev = 9;
              _context2.t0 = _context2["catch"](0);
              console.log('Error retrieving pdf: ', _context2.t0);

            case 12:
            case "end":
              return _context2.stop();
          }
        }
      }, _callee2, null, [[0, 9]]);
    }));

    return function getSavedResultsPdf(_x2) {
      return _ref2.apply(this, arguments);
    };
  }();

  var callForResultsPdf = function () {
    var _ref3 = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee3() {
      var requestObj, response, pdfUrl;
      return _regenerator["default"].wrap(function _callee3$(_context3) {
        while (1) {
          switch (_context3.prev = _context3.next) {
            case 0:
              _context3.prev = 0;

              _VASApi["default"].apiClient.setHeader('Authorization', "".concat(authToken));

              props.resultsDtoObj.CreatedAt = new Date().toISOString();
              requestObj = {
                vascassessmentresults: props.resultsDtoObj,
                vascassessmentchoices: props.choicesDtoObj
              };
              _context3.next = 6;
              return getResultsPdf(requestObj);

            case 6:
              response = _context3.sent;
              pdfUrl = JSON.parse(response.data).URI;
              props.retrievedPdfLink(pdfUrl);
              _context3.next = 14;
              break;

            case 11:
              _context3.prev = 11;
              _context3.t0 = _context3["catch"](0);
              console.log('Error retrieving pdf: ', _context3.t0);

            case 14:
            case "end":
              return _context3.stop();
          }
        }
      }, _callee3, null, [[0, 11]]);
    }));

    return function callForResultsPdf() {
      return _ref3.apply(this, arguments);
    };
  }();

  var loadVasData = function () {
    var _ref4 = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee4() {
      var getChoicesResponse, tempPatientSummary, patientData, choicesObj, _VascularAccessSelect, resultsDict, dataDict, entries, tempSortedEntries;

      return _regenerator["default"].wrap(function _callee4$(_context4) {
        while (1) {
          switch (_context4.prev = _context4.next) {
            case 0:
              getSavedResultsPdf(props.vasId);
              _context4.next = 3;
              return getVasChoicesRequest(props.vasId);

            case 3:
              getChoicesResponse = _context4.sent;

              if (getChoicesResponse.ok) {
                tempPatientSummary = {};
                patientData = JSON.parse(getChoicesResponse.data);
                console.log(patientData);
                tempPatientSummary['age'] = _application.VAS_CHOICE_TO_LABEL[patientData.Age];
                tempPatientSummary['dialysis'] = _application.VAS_CHOICE_TO_LABEL[patientData.Dialysis];
                tempPatientSummary['facility'] = _application.VAS_CHOICE_TO_LABEL[patientData.Facility];
                tempPatientSummary['bmi'] = _application.VAS_CHOICE_TO_LABEL[patientData.BMI];
                tempPatientSummary['cephalic_vein'] = _application.VAS_CHOICE_TO_LABEL[patientData.CephalicVein];
                tempPatientSummary['basilic_vein'] = _application.VAS_CHOICE_TO_LABEL[patientData.BasilicVein];
                tempPatientSummary['artery'] = _application.VAS_CHOICE_TO_LABEL[patientData.Artery];
                tempPatientSummary['vas_id'] = props.vasId;
                choicesObj = {
                  age: patientData.Age,
                  dialysis: patientData.Dialysis,
                  facility: patientData.Facility,
                  bmi: patientData.BMI,
                  basilic_vein: patientData.CephalicVein,
                  cephalic_vein: patientData.BasilicVein,
                  artery: patientData.Artery
                };
                _VascularAccessSelect = _VascularAccessSelectionService["default"].calculateResults(choicesObj), resultsDict = _VascularAccessSelect.resultsDict, dataDict = _VascularAccessSelect.dataDict;
                entries = Object.entries(resultsDict);
                tempSortedEntries = entries.sort(function (a, b) {
                  return b[1] - a[1];
                });
                setPatientSummary(tempPatientSummary);
                setSortedEntries(tempSortedEntries);
              }

            case 5:
            case "end":
              return _context4.stop();
          }
        }
      }, _callee4);
    }));

    return function loadVasData() {
      return _ref4.apply(this, arguments);
    };
  }();

  var handleMoreInfoClick = function handleMoreInfoClick() {
    if (showMoreInfo) {
      setShowMoreInfo(false);
    } else {
      setShowMoreInfo(true);
    }
  };

  var renderItem = function renderItem(item, key) {
    var stars = [];

    for (var i = 0; i < 5; i++) {
      stars.push(_react["default"].createElement(_Primitives.Box, {
        style: _objectSpread(_objectSpread({}, _VascularAccessResultsStyles.Styles.starContainer), props.starContainerStyle),
        __self: _this,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 246,
          columnNumber: 9
        }
      }, _react["default"].createElement(_AppIcon["default"], {
        size: "xxl",
        name: i < item[1] ? 'star' : 'star-o',
        color: i < item[1] ? _utils.Colors.PRIMARY_MEDIUM : _utils.Colors.GRAY_MEDIUM,
        __self: _this,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 247,
          columnNumber: 11
        }
      })));
    }

    return _react["default"].createElement(_Primitives.Box, {
      key: key,
      style: _objectSpread(_objectSpread({}, _VascularAccessResultsStyles.Styles.itemContainer), props.itemContainerStyle),
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 258,
        columnNumber: 7
      }
    }, _react["default"].createElement(_AppText["default"], {
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 261,
        columnNumber: 9
      }
    }, item[0]), _react["default"].createElement(_Primitives.Box, {
      style: _objectSpread(_objectSpread({}, _VascularAccessResultsStyles.Styles.starsContainer), props.starsContainerStyle),
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 262,
        columnNumber: 9
      }
    }, stars));
  };

  return _react["default"].createElement(_Primitives.Box, {
    style: _objectSpread(_objectSpread({}, _VascularAccessResultsStyles.Styles.container), props.containerStyle),
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 270,
      columnNumber: 5
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.title,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 271,
      columnNumber: 7
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.titleText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 275,
      columnNumber: 9
    }
  }, "AV-Access (Fistula or Graft) Appropriateness Ranking")), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.moreInfoSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 279,
      columnNumber: 7
    }
  }, _react["default"].createElement(_Primitives.TouchableBox, {
    style: _VascularAccessResultsStyles.Styles.moreInfoLabelSection,
    onPress: handleMoreInfoClick,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 280,
      columnNumber: 9
    }
  }, _react["default"].createElement(_AppText["default"], {
    iconSize: 'xxl',
    iconName: showMoreInfo ? 'caret-up' : 'caret-down',
    iconColor: _utils.Colors.ORANGE,
    textStyle: _VascularAccessResultsStyles.Styles.moreInfoLabelSectionText,
    alignRight: true,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 283,
      columnNumber: 11
    }
  }, "What does this mean?", ' ')), showMoreInfo && _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.moreInfoContent,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 293,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.moreInfoText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 294,
      columnNumber: 13
    }
  }, "The Appropriateness Rankings uses patient-specific factors to select the most appropriate vascular access for each patient."), _react["default"].createElement(_Primitives.Box, {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 298,
      columnNumber: 13
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.moreInfoStarDescContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 299,
      columnNumber: 15
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.moreInfoStarDescText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 300,
      columnNumber: 17
    }
  }, "5 stars", ' '), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 303,
      columnNumber: 17
    }
  }, "indicates an appropriate option.")), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.moreInfoStarDescContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 305,
      columnNumber: 15
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.moreInfoStarDescText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 306,
      columnNumber: 17
    }
  }, "3 stars", ' '), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 309,
      columnNumber: 17
    }
  }, "indicates an indeterminate option.")), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.moreInfoStarDescContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 311,
      columnNumber: 15
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.moreInfoStarDescText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 312,
      columnNumber: 17
    }
  }, "1 stars", ' '), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 315,
      columnNumber: 17
    }
  }, "indicates an inappropriate option."))))), _react["default"].createElement(_Primitives.Box, {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 321,
      columnNumber: 7
    }
  }, sortedEntries.map(function (item, i) {
    return renderItem(item, i);
  })), _react["default"].createElement(_Primitives.TouchableBox, {
    onPress: function onPress() {
      props.navigate({
        screen: _application.NavigationScreen.ABOUT,
        props: {}
      });
    },
    style: _VascularAccessResultsStyles.Styles.moreAboutProcessContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 322,
      columnNumber: 7
    }
  }, _react["default"].createElement(_AppText["default"], {
    iconSize: "medium",
    iconName: "angle-double-right",
    iconColor: _utils.Colors.ORANGE,
    textStyle: _VascularAccessResultsStyles.Styles.moreAboutProcessText,
    alignRight: true,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 327,
      columnNumber: 9
    }
  }, "More about our process", ' ')), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.patientSummarySection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 336,
      columnNumber: 7
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.patientSummaryTitle,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 337,
      columnNumber: 9
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.patientSummaryTitleText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 338,
      columnNumber: 11
    }
  }, "PATIENT SUMMARY")), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.summarySection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 342,
      columnNumber: 9
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.summaryLine,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 343,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 344,
      columnNumber: 13
    }
  }, "Age: "), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 345,
      columnNumber: 13
    }
  }, patientSummary['age'])), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.summaryLine,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 347,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 348,
      columnNumber: 13
    }
  }, "Dialysis: "), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 349,
      columnNumber: 13
    }
  }, patientSummary['dialysis'])), _react["default"].createElement(_Primitives.Box, {
    style: _objectSpread(_objectSpread({}, _VascularAccessResultsStyles.Styles.summaryLine), {}, {
      marginBottom: 30
    }),
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 351,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 352,
      columnNumber: 13
    }
  }, "Functionality: "), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 353,
      columnNumber: 13
    }
  }, patientSummary['facility'])), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.summaryLine,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 355,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 356,
      columnNumber: 13
    }
  }, "BMI: "), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 357,
      columnNumber: 13
    }
  }, patientSummary['bmi'])), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.summaryLine,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 359,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 360,
      columnNumber: 13
    }
  }, "Cephalic Vein: "), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 361,
      columnNumber: 13
    }
  }, patientSummary['cephalic_vein'])), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.summaryLine,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 363,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 364,
      columnNumber: 13
    }
  }, "Basilic Vein: "), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 365,
      columnNumber: 13
    }
  }, patientSummary['basilic_vein'])), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.summaryLine,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 367,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 368,
      columnNumber: 13
    }
  }, "Artery Radial: "), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 369,
      columnNumber: 13
    }
  }, patientSummary['artery'])), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.summaryLine,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 371,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 372,
      columnNumber: 13
    }
  }, "VAS ID: "), props.user ? _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 374,
      columnNumber: 15
    }
  }, patientSummary['vas_id']) : _react["default"].createElement(_Primitives.TextSpan, {
    style: {
      color: 'red'
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 376,
      columnNumber: 15
    }
  }, "Please log in to save VAS results.")))), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.footer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 381,
      columnNumber: 7
    }
  }, pdfLoaded() && _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessResultsStyles.Styles.footerButtonContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 383,
      columnNumber: 11
    }
  }, _react["default"].createElement(_Primitives.TouchableBox, {
    onPress: function onPress() {
      return props.handlePdfClick();
    },
    style: _VascularAccessResultsStyles.Styles.footerButtons,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 384,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppIcon["default"], {
    name: "file-pdf-o",
    size: "xxl",
    color: _utils.Colors.PRIMARY,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 387,
      columnNumber: 15
    }
  }), _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.footerButtonText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 388,
      columnNumber: 15
    }
  }, "View PDF")), _react["default"].createElement(_Primitives.TouchableBox, {
    onPress: function onPress() {
      props.navigate({
        screen: _application.NavigationScreen.HOME,
        props: {}
      });
    },
    style: _VascularAccessResultsStyles.Styles.footerButtons,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 392,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppIcon["default"], {
    name: "home",
    size: "xxl",
    color: _utils.Colors.PRIMARY,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 397,
      columnNumber: 15
    }
  }), _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessResultsStyles.Styles.footerButtonText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 398,
      columnNumber: 15
    }
  }, "Home")))));
};

var _default = VascularAccessResults;
exports["default"] = _default;