"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = exports.IconSizes = void 0;

var _react = _interopRequireDefault(require("react"));

var _Primitives = require("../primitives/Primitives");

var _this = void 0,
    _jsxFileName = "/home/vsts/work/1/s/common/build/web/temp/components/AppIcon.tsx";

var IconSizes = {
  small: 13,
  medium: 18,
  large: 24,
  extraLarge: 28,
  xxl: 34,
  xxxl: 42
};
exports.IconSizes = IconSizes;

var AppIcon = function AppIcon(props) {
  return _react["default"].createElement(_Primitives.Icon, {
    name: props.name,
    size: IconSizes[props.size],
    color: props.color,
    isMaterialIcon: props.isMaterialIcon ? props.isMaterialIcon : false,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 23,
      columnNumber: 5
    }
  });
};

var _default = AppIcon;
exports["default"] = _default;