import {
  Colors,
  SimpleNavigationMenu,
  PairVaLogo as PVLogo,
  AppIcon,
  INavigation,
  NavigationScreen,
  useLocalStorage
} from '@pairva/common/build/web/dist';
import { onNavigate } from '../util/util';
import { PairVaLogo } from '../assets/logos';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import DrawerNav from '../components/DrawerNav';
import { useMsal } from "@azure/msal-react";
import { userContext } from '../context/userContext';

const NavBar = (props) => {
  const history = useHistory();
  const [user, setUser] = useLocalStorage('user', undefined);
  const { instance, accounts, inProgress } = useMsal();

  useEffect(() => {
    window.removeEventListener('resize', handleResize);
    window.addEventListener('resize', handleResize);
  }, []);


  const [width, setWidth] = useState(window.innerWidth);
  const [openDrawer, setOpenDrawer] = useState(true);

  const handleResize = () => {
    setWidth(window.innerWidth);
  };

  return (
    <>
      <div style={styles.header}>
        <div
          onClick={() => onNavigate(history, { screen: NavigationScreen.HOME })}
          style={styles.headerLogoContainer}>
          <PairVaLogo width={'100%'} height={'100%'} />
        </div>
        {/* <img alt={'logo'} src={require('../assets/logos/LOGO-vertical-white.svg')} width={500} height={500} /> */}

        <div style={styles.headerMenuContainer}>
          {width && width > 1100 ? (
            <SimpleNavigationMenu
              containerStyle={styles.simpleNavigationMenuContainer}
              itemStyle={styles.simpleNavigationMenuItem}
              navigate={(navigator: InstanceType<typeof INavigation>) =>
                onNavigate(history, navigator)
              }
              user={user}
              // loginFunction={login}
            />
          ) : (
            <div onClick={() => setOpenDrawer(!openDrawer)}>
              <AppIcon
                isMaterialIcon={true}
                name={'menu'}
                color={Colors.WHITE_LIGHT}
                size={'xxl'}
              />
              <DrawerNav
                openDrawer={openDrawer}
                navigate={(navigator) =>
                  onNavigate(history, navigator)
                }
              />
            </div>

          )}
        </div>
      </div>
    </>
  );
};

export default NavBar;

const styles = {
  header: {
    backgroundColor: Colors.PRIMARY_MEDIUM,
    borderBottomLeftRadius: 45,
    borderBottomRightRadius: 45,
    borderLeftWidth: 2,
    borderRightWidth: 2,
    borderBottomWidth: 2,
    // paddingBottom: 30,
    flexDirection: 'row' as 'row',
    display: 'flex',
    flex: 1,
  },
  headerMenuContainer: {
    justifyContent: 'flex-end',
    flexShrink: 1,
    flexGrow: 1,
    alignSelf: 'center',
    display: 'flex',
    paddingLeft: 10,
    paddingRight: 50,
  },
  simpleNavigationMenuContainer: {
    flexDirection: 'row' as 'row',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  simpleNavigationMenuItem: {
    padding: 10,
    paddingLeft: 16,
    paddingRight: 16,
  },
  headerLogoContainer: {
    width: 200,
    alignItems: 'center',
    justifyContent: 'flex-start',
    alignSelf: 'center',
    margin: 20,
    paddingLeft: 40,
    paddingRight: 40,
  },
};
