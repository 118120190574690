import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import "./App.css";
import NavBar from "./navigation/NavBar";
import VascularAccessScreen from "./screens/VascularAccessScreen";
import HomeScreen from "./screens/HomeScreen";
import AboutScreen from "./screens/AboutScreen";
import { Colors } from "@pairva/common/build/web/dist";
import VascularAccessResultsScreen from "./screens/VascularAccessResultsScreen";
import Footer from "./components/Footer";
import ClinicalSituationsHomeScreen from "./screens/ClinicalSituationsHomeScreen";
import ClinicalSituationsFlowScreen from "./screens/ClinicalSituationsFlowScreen";
import ResourcesScreen from "./screens/ResourcesScreen";
import HistoryScreen from "./screens/HistoryScreen";
import { userContext } from './context/userContext';
import SignOutScreen from "./screens/SignOut";
import { IPublicClientApplication } from "@azure/msal-browser";
import SupportScreen from "./screens/SupportScreen";

type AppProps = {
  pca: IPublicClientApplication
};


const App = () => {

  const [user, setUser] = useState(null)
  const redirect_uri: string = 'https%3A%2F%2Fwww.myvascularaccess.com%2Fhome';
  // const redirect_uri: string = 'http%3A%2F%2Flocalhost%3A3000%2Fhome';

  useEffect(() => { }, []);

  const AppStack = () => (
    <>
      <div style={appStyles.appStackContentContainer}>
        <div style={appStyles.appStackHeaderContainer}>
          <NavBar />
        </div>
        <div style={appStyles.appStackPageContainer}>
          <Route exact path="/" render={() => <Redirect to={"/home"} />} />
          <Route exact path="/home" component={HomeScreen} />
          <Route
            exact
            path="/vascularaccessscreen"
            component={VascularAccessScreen}
          />
          <Route
            exact
            path="/vascularaccessresultsscreen"
            component={VascularAccessResultsScreen}
          />
          <Route
            exact
            path="/clinicalsituationshomescreen"
            component={ClinicalSituationsHomeScreen}
          />
          <Route
            exact
            path="/clinicalsituationsflowscreen"
            component={ClinicalSituationsFlowScreen}
          />
          <Route exact path="/resourcesscreen" component={ResourcesScreen} />
          <Route exact path="/aboutscreen" component={AboutScreen} />
          <Route exact path="/historyscreen" component={HistoryScreen} />
          <Route exact path="/support" component={SupportScreen} />
          <Route exact path="/editprofile" component={() => {
            window.location.href = `https://pairvaprod.b2clogin.com/pairvaprod.onmicrosoft.com/oauth2/v2.0/`
              + `authorize?p=B2C_1_EditProfile&client_id=9ade17e7-facf-46ce-a8fb-2f1b36bdf586&nonce=defaultNonce`
              + `&redirect_uri=${redirect_uri}`
              + `&scope=https%3A%2F%2FPAIRVAPROD.onmicrosoft.com%2F9ade17e7-facf-46ce-a8fb-2f1b36bdf586%2FFiles.Read&response_type=token`;
            return null;
          }} />
          {/* <Route exact path="/register" component={() => {
            pca.loginRedirect();
          }} /> */}
          <Route path='/register' component={() => {
            // window.location.href = `https://PAIRVA.b2clogin.com/PAIRVA.onmicrosoft.com/B2C_1_signupsignin/oauth2/v2.0/`
            //   + `authorize?client_id=8e50ec42-acb0-45b6-919c-67362abb11bf&nonce=anyRandomValue`
            //   + `&redirect_uri=${redirect_uri}`
            //   + `&scope=https://PAIRVA.onmicrosoft.com/8e50ec42-acb0-45b6-919c-67362abb11bf/Files.Read openid offline_access&response_type=code`;
            window.location.href = `https://pairvaprod.b2clogin.com/pairvaprod.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_signupsignin&client_id=9ade17e7-facf-46ce-a8fb-2f1b36bdf586&nonce=defaultNonce&redirect_uri=${redirect_uri}&scope=openid%20https%3A%2F%2Fpairvaprod.onmicrosoft.com%2F9ade17e7-facf-46ce-a8fb-2f1b36bdf586%2FFiles.Read offline_access openid&prompt=login&response_type=code`;
            return null;
          }} />
          <Route path='/signout' component={SignOutScreen} />
        </div>
        <div style={appStyles.appStackFooterContainer}>
          <Footer />
        </div>
      </div>
    </>
  );

  return (
    <div style={appStyles.appContainer}>
      <link
        rel="stylesheet"
        href="https://fonts.googleapis.com/icon?family=Material+Icons"
      />
      <userContext.Provider value={{ user, setUser }}>
        <BrowserRouter>
          <Switch>
            <Route component={AppStack} />
          </Switch>
        </BrowserRouter>
      </userContext.Provider>

    </div>
  );
};

export default App;

const appStyles = {
  appContainer: {
    position: "absolute" as "absolute",
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    backgroundColor: Colors.WHITE_LIGHT,
  },

  appStackHeaderContainer: {
    display: "flex",
  },

  appStackFooterContainer: {
    flex: 3,
    flexShrink: 0,
  },

  appStackPageContainer: {
    display: "flex",
    flexGrow: 1,
    flexShrink: 1,
    flex: 6,
  },

  appStackContentContainer: {
    display: "flex",
    backgroundColor: Colors.WHITE_LIGHT,
    flexDirection: "column" as "column",
    height: "100%",
  },
};
