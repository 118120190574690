import { useLocalStorage } from '@pairva/common/build/web/dist';
import { IAuthToken } from '@pairva/common/src/models/application';
import jwtDecode from 'jwt-decode';


// Swap redirect uri here
const redirect_uri: string = 'https://www.myvascularaccess.com/home';
// const redirect_uri: string = 'http://localhost:3000/home';
// http%3A%2F%2Flocalhost%3A3000%2Fhome


export const getValidToken = async(token: IAuthToken): Promise<IAuthToken> => {
    if (!token) {
        return undefined;
    }

    const now = Date.now();
    if (now >= token.expires_on) {
        if (now >= token.refresh_token_expires_on) {
            // refresh token expired, need to reauthenticate user
            return undefined;
        }
        else {
            // jwt token has expired
            let refreshResponse = await refreshAuthToken(token.refresh_token);
            let responseObject = await refreshResponse.json();

            let tokenExpiry = Date.now() + (responseObject.expires_in * 1000);

            let authToken: IAuthToken = {
                access_token: responseObject.access_token,
                expires_on: tokenExpiry,
                refresh_token: responseObject.refresh_token,
                refresh_token_expires_on: token.refresh_token_expires_on
            };
            
            return authToken;
        }
    }
    else {
        // jwt token is valid
        return token;
    }
}

export const GetIAuthToken = async (code: string): Promise<IAuthToken> => {
    // Retrieve token from Azure AD
    let response = await retrieveAuthToken(code);
    let responseObject = await response.json();

    // Setting future expiration for refresh token
    let tokenExpiry = responseObject.expires_on * 1000;
    let refreshTokenExpiry = Date.now() + (responseObject.refresh_token_expires_in * 1000);

    let authToken: IAuthToken = {
        access_token: responseObject.access_token,
        expires_on: tokenExpiry,
        refresh_token: responseObject.refresh_token,
        refresh_token_expires_on: refreshTokenExpiry
    };

    return authToken;
}

export const retrieveAuthToken = async (code: string): Promise<any> => {
    // DEV ENDPOINT
    // let getTokenUri = `https://PAIRVA.b2clogin.com/PAIRVA.onmicrosoft.com/B2C_1_signupsignin/oauth2/v2.0/`
    //     + `token?grant_type=authorization_code&client_id=8e50ec42-acb0-45b6-919c-67362abb11bf&code=${code}`
    //     + `&redirect_uri=${redirect_uri}&client_secret=ewQyTayf_g_0TtmcnG0x22tc_~XaH~34l8`;
    
    // PROD ENDPOINT
    let getTokenUri = `https://PAIRVAPROD.b2clogin.com/PAIRVAPROD.onmicrosoft.com/B2C_1_signupsignin/oauth2/v2.0/`
        + `token?grant_type=authorization_code&client_id=9ade17e7-facf-46ce-a8fb-2f1b36bdf586&code=${code}`
        + `&redirect_uri=${redirect_uri}&client_secret=_i.D-AH-79IR~9o0979ts4lwVuy31mOSZN`;

    return await fetch(getTokenUri, {
        method: 'POST'
    })
}

export const refreshAuthToken = async (refreshToken: string): Promise<any> => {
    // DEV ENDPOINT
    // let getTokenUri = `https://PAIRVA.b2clogin.com/PAIRVAPROD.onmicrosoft.com/B2C_1_signupsignin/oauth2/v2.0/`
    //     + `token?grant_type=refresh_token&client_id=8e50ec42-acb0-45b6-919c-67362abb11bf`
    //     + `&scope=https://PAIRVA.onmicrosoft.com/8e50ec42-acb0-45b6-919c-67362abb11bf/Files.Read openid offline_access`
    //     + `&refresh_token=${refreshToken}&redirect_uri=${redirect_uri}&client_secret=ewQyTayf_g_0TtmcnG0x22tc_~XaH~34l8`;

    // PROD ENDPOINT
    let getTokenUri = `https://PAIRVAPROD.b2clogin.com/PAIRVAPROD.onmicrosoft.com/B2C_1_signupsignin/oauth2/v2.0/`
        + `token?grant_type=refresh_token&client_id=9ade17e7-facf-46ce-a8fb-2f1b36bdf586`
        + `&scope=https://PAIRVAPROD.onmicrosoft.com/9ade17e7-facf-46ce-a8fb-2f1b36bdf586/Files.Read openid offline_access`
        + `&refresh_token=${refreshToken}&redirect_uri=${redirect_uri}&client_secret=_i.D-AH-79IR~9o0979ts4lwVuy31mOSZN`;


    return await fetch(getTokenUri, {
        method: 'POST'
    });
}