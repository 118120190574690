"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styles = void 0;

var _utils = require("../../utils");

var Styles = {
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: _utils.Colors.WHITE_LIGHT,
    marginHorizontal: 15
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: _utils.Spacing.FONT_SIZE_24
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 20
  },
  headerContainer: {
    display: 'flex',
    flexDirection: "row"
  },
  backButtonSection: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 50,
    display: "flex"
  },
  historyTab: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    borderStyle: 'solid',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: _utils.Colors.GRAY_MEDIUM,
    paddingTop: 10,
    paddingBottom: 10,
    marginRight: 10,
    marginLeft: 10
  },
  historyTabText: {
    flexDirection: "row",
    display: "flex",
    justifyContent: "center"
  },
  vapsidBox: {
    marginLeft: 30,
    flexDirection: "row"
  },
  applyFilters: {
    alignSelf: "flex-start",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: _utils.Colors.PRIMARY_MEDIUM,
    borderRadius: 5,
    width: 100,
    height: 25,
    marginTop: 10,
    marginBottom: 10,
    display: "flex"
  },
  toggleFilters: {
    alignSelf: "flex-start",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: _utils.Colors.PRIMARY_MEDIUM,
    borderRadius: 5,
    width: 100,
    height: 25,
    marginBottom: 10,
    display: "flex"
  },
  filters: {
    marginTop: 10,
    marginBottom: 10,
    marginLeft: 20,
    justifyContent: "space-around",
    borderBottomColor: _utils.Colors.GRAY_SPACE,
    borderBottomWidth: 1,
    flexDirection: "column",
    paddingBottom: 10
  }
};
exports.Styles = Styles;