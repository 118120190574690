"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _regenerator = _interopRequireDefault(require("@babel/runtime/regenerator"));

var _asyncToGenerator2 = _interopRequireDefault(require("@babel/runtime/helpers/asyncToGenerator"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _Primitives = require("../primitives/Primitives");

var _AppText = _interopRequireDefault(require("./AppText"));

var _VascularAccessSelectionService = _interopRequireDefault(require("../services/VascularAccessSelectionService"));

var _utils = require("../utils");

var _VascularAccessSelectionAssistantStyles = require("../styles/VascularAccessSelectionAssistant/VascularAccessSelectionAssistantStyles");

var _application = require("../models/application");

var _react = _interopRequireWildcard(require("react"));

var _vas_page_data = require("../data/vas_page_data");

var _AppIcon = _interopRequireDefault(require("./AppIcon"));

var _AppButton = _interopRequireDefault(require("./AppButton"));

var _this = void 0,
    _jsxFileName = "/home/vsts/work/1/s/common/build/web/temp/components/VascularAccessSelectionAssistant.tsx";

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var vascularAccessSelectionReducer = function vascularAccessSelectionReducer(state, action) {
  switch (action.type) {
    case 'field':
      return _objectSpread(_objectSpread({}, state), {}, (0, _defineProperty2["default"])({}, action.field, action.value));

    default:
      return state;
  }

  return state;
};

var initialState = {
  ageIndex: -1,
  dialysisIndex: -1,
  facilityIndex: -1,
  bmiIndex: -1,
  basilicIndex: -1,
  cephalicIndex: -1,
  arteryIndex: -1,
  roleIndex: -1,
  showMoreInfo: false,
  activePage: 1
};

var VascularAccessSelectionAssistant = function VascularAccessSelectionAssistant(props) {
  var _useReducer = (0, _react.useReducer)(vascularAccessSelectionReducer, initialState),
      _useReducer2 = (0, _slicedToArray2["default"])(_useReducer, 2),
      state = _useReducer2[0],
      dispatch = _useReducer2[1];

  var jsonData = _vas_page_data.VasPageData;
  var roles = [{
    label: 'Surgeon'
  }, {
    label: 'Nephrologist'
  }, {
    label: 'Nurse'
  }, {
    label: 'Technician'
  }, {
    label: 'Patient'
  }, {
    label: 'Other'
  }];

  var handleSubmit = function () {
    var _ref = (0, _asyncToGenerator2["default"])(_regenerator["default"].mark(function _callee() {
      var _props$user;

      var ageItem, dialysisItem, facilityItem, bmiItem, cephalicItem, basilicItem, arteryItem, results, resultLabels, _VascularAccessSelect, resultsDict, dataDict, choices;

      return _regenerator["default"].wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              ageItem = jsonData.pages[0].questions[0].options[state.ageIndex];
              dialysisItem = jsonData.pages[0].questions[1].options[state.dialysisIndex];
              facilityItem = jsonData.pages[0].questions[2].options[state.facilityIndex];
              bmiItem = jsonData.pages[1].questions[0].options[state.bmiIndex];
              cephalicItem = jsonData.pages[1].questions[1].options[state.cephalicIndex];
              basilicItem = jsonData.pages[1].questions[2].options[state.basilicIndex];
              arteryItem = jsonData.pages[1].questions[3].options[state.arteryIndex];
              results = {
                age: ageItem.id,
                dialysis: dialysisItem.id == 'IMMINENT' ? 1 : 0,
                facility: facilityItem.id,
                bmi: bmiItem.id,
                basilic_vein: basilicItem.id,
                cephalic_vein: cephalicItem.id,
                artery: arteryItem.id
              };
              resultLabels = {
                age: ageItem.label,
                dialysis: dialysisItem.label,
                facility: facilityItem.label,
                bmi: bmiItem.label,
                basilic_vein: basilicItem.label,
                cephalic_vein: cephalicItem.label,
                artery: arteryItem.label
              };
              _VascularAccessSelect = _VascularAccessSelectionService["default"].calculateResults(results), resultsDict = _VascularAccessSelect.resultsDict, dataDict = _VascularAccessSelect.dataDict;
              choices = {
                Age: ageItem.id,
                Dialysis: dialysisItem.id == 'IMMINENT' ? 1 : 0,
                Facility: facilityItem.id,
                BMI: bmiItem.id,
                BasilicVein: basilicItem.id,
                CephalicVein: cephalicItem.id,
                Artery: arteryItem.id,
                UserId: (_props$user = props.user) === null || _props$user === void 0 ? void 0 : _props$user['sub']
              };
              console.log('from vas assis', choices);
              props.navigate({
                screen: _application.NavigationScreen.VASRESULTS,
                props: {
                  resultsDict: resultsDict,
                  patientSummary: resultLabels,
                  choicesDtoObj: choices,
                  resultsDtoObj: dataDict
                }
              });

            case 13:
            case "end":
              return _context.stop();
          }
        }
      }, _callee);
    }));

    return function handleSubmit() {
      return _ref.apply(this, arguments);
    };
  }();

  var currentPage = jsonData.pages.find(function (page) {
    return page.page_number == state.activePage;
  });
  var numberOfPages = jsonData.pages.length;

  var isNextDisabled = function isNextDisabled() {
    switch (state.activePage) {
      case 1:
        if (state.ageIndex >= 0 && state.dialysisIndex >= 0 && state.facilityIndex >= 0) {
          return false;
        } else {
          return true;
        }

      case 2:
        if (state.bmiIndex >= 0 && state.basilicIndex >= 0 && state.cephalicIndex >= 0 && state.arteryIndex >= 0) {
          return false;
        } else {
          return true;
        }

    }
  };

  return _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.container,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 192,
      columnNumber: 5
    }
  }, state.activePage == 1 && _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.pageBody,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 194,
      columnNumber: 9
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.titleContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 195,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.title,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 196,
      columnNumber: 13
    }
  }, currentPage.page_title)), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.section,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 198,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 199,
      columnNumber: 13
    }
  }, currentPage.questions[0].title), _react["default"].createElement(_Primitives.RadioButton, {
    deactiveColor: _utils.Colors.PRIMARY_MEDIUM,
    box: false,
    style: _VascularAccessSelectionAssistantStyles.Styles.option,
    options: currentPage.questions[0].options,
    activeColor: _utils.Colors.PRIMARY_MEDIUM,
    onSelected: function onSelected(e, i) {
      dispatch({
        type: 'field',
        field: 'ageIndex',
        value: i
      });
    },
    value: state.ageIndex,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 200,
      columnNumber: 13
    }
  })), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.section,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 212,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 213,
      columnNumber: 13
    }
  }, currentPage.questions[1].title), _react["default"].createElement(_Primitives.RadioButton, {
    deactiveColor: _utils.Colors.PRIMARY_MEDIUM,
    box: false,
    style: _VascularAccessSelectionAssistantStyles.Styles.option,
    options: currentPage.questions[1].options,
    activeColor: _utils.Colors.PRIMARY_MEDIUM,
    onSelected: function onSelected(e, i) {
      dispatch({
        type: 'field',
        field: 'dialysisIndex',
        value: i
      });
    },
    value: state.dialysisIndex,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 214,
      columnNumber: 13
    }
  })), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.section,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 226,
      columnNumber: 11
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.moreInfoContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 227,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 228,
      columnNumber: 15
    }
  }, currentPage.questions[2].title, " | "), _react["default"].createElement(_Primitives.TouchableBox, {
    onPress: function onPress() {
      dispatch({
        type: 'field',
        field: 'showMoreInfo',
        value: !state.showMoreInfo
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 229,
      columnNumber: 15
    }
  }, state.showMoreInfo == true ? _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.moreInfoText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 238,
      columnNumber: 19
    }
  }, "Hide Info") : _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.moreInfoText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 240,
      columnNumber: 19
    }
  }, "More Info"))), state.showMoreInfo && _react["default"].createElement(_Primitives.Box, {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 245,
      columnNumber: 15
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.moreInfoItemContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 246,
      columnNumber: 17
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.moreInfoItemText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 247,
      columnNumber: 19
    }
  }, "Independent:", ' '), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 250,
      columnNumber: 19
    }
  }, "Patient is able to perform all activities of daily living without assistance.")), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.moreInfoItemContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 255,
      columnNumber: 17
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.moreInfoItemText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 256,
      columnNumber: 19
    }
  }, "Assisted (lives at home):", ' '), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 259,
      columnNumber: 19
    }
  }, "Patient requires assistance to perform most activities of daily living but is able to live outside of a facility.")), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.moreInfoItemContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 264,
      columnNumber: 17
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.moreInfoItemText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 265,
      columnNumber: 19
    }
  }, "Dependent (lives in a facility):", ' '), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 268,
      columnNumber: 19
    }
  }, "Patient requires assistance to perform nearly all activities of daily living and requires 24-hour care in a facility (e.g. a nursing home)."))), _react["default"].createElement(_Primitives.RadioButton, {
    deactiveColor: _utils.Colors.PRIMARY_MEDIUM,
    box: false,
    style: _VascularAccessSelectionAssistantStyles.Styles.option,
    options: currentPage.questions[2].options,
    activeColor: _utils.Colors.PRIMARY_MEDIUM,
    onSelected: function onSelected(e, i) {
      dispatch({
        type: 'field',
        field: 'facilityIndex',
        value: i
      });
    },
    value: state.facilityIndex,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 277,
      columnNumber: 13
    }
  }))), state.activePage == 2 && _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.pageBody,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 292,
      columnNumber: 9
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.titleContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 293,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.title,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 294,
      columnNumber: 13
    }
  }, currentPage.page_title)), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.section,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 296,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 297,
      columnNumber: 13
    }
  }, currentPage.questions[0].title), _react["default"].createElement(_Primitives.RadioButton, {
    deactiveColor: _utils.Colors.PRIMARY_MEDIUM,
    box: false,
    style: _VascularAccessSelectionAssistantStyles.Styles.option,
    options: currentPage.questions[0].options,
    activeColor: _utils.Colors.PRIMARY_MEDIUM,
    onSelected: function onSelected(e, i) {
      dispatch({
        type: 'field',
        field: 'bmiIndex',
        value: i
      });
    },
    value: state.bmiIndex,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 298,
      columnNumber: 13
    }
  })), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.section,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 310,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 311,
      columnNumber: 13
    }
  }, currentPage.questions[1].title), _react["default"].createElement(_Primitives.RadioButton, {
    deactiveColor: _utils.Colors.PRIMARY_MEDIUM,
    box: false,
    style: _VascularAccessSelectionAssistantStyles.Styles.option,
    options: currentPage.questions[1].options,
    activeColor: _utils.Colors.PRIMARY_MEDIUM,
    onSelected: function onSelected(e, i) {
      dispatch({
        type: 'field',
        field: 'cephalicIndex',
        value: i
      });
    },
    value: state.cephalicIndex,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 312,
      columnNumber: 13
    }
  })), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.section,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 324,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 325,
      columnNumber: 13
    }
  }, currentPage.questions[2].title), _react["default"].createElement(_Primitives.RadioButton, {
    deactiveColor: _utils.Colors.PRIMARY_MEDIUM,
    box: false,
    style: _VascularAccessSelectionAssistantStyles.Styles.option,
    options: currentPage.questions[2].options,
    activeColor: _utils.Colors.PRIMARY_MEDIUM,
    onSelected: function onSelected(e, i) {
      dispatch({
        type: 'field',
        field: 'basilicIndex',
        value: i
      });
    },
    value: state.basilicIndex,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 326,
      columnNumber: 13
    }
  })), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.section,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 338,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 339,
      columnNumber: 13
    }
  }, currentPage.questions[3].title), _react["default"].createElement(_Primitives.RadioButton, {
    deactiveColor: _utils.Colors.PRIMARY_MEDIUM,
    box: false,
    style: _VascularAccessSelectionAssistantStyles.Styles.option,
    options: currentPage.questions[3].options,
    activeColor: _utils.Colors.PRIMARY_MEDIUM,
    onSelected: function onSelected(e, i) {
      dispatch({
        type: 'field',
        field: 'arteryIndex',
        value: i
      });
    },
    value: state.arteryIndex,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 340,
      columnNumber: 13
    }
  }))), state.activePage == 3 && _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.pageBody,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 355,
      columnNumber: 9
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.titleContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 356,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.title,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 357,
      columnNumber: 13
    }
  }, "Patient Summary")), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.summarySection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 359,
      columnNumber: 11
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.summaryLine,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 360,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 361,
      columnNumber: 15
    }
  }, "Age: "), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 362,
      columnNumber: 15
    }
  }, jsonData.pages[0].questions[0].options[state.ageIndex].label)), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.summaryLine,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 366,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 367,
      columnNumber: 15
    }
  }, "Dialysis: "), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 368,
      columnNumber: 15
    }
  }, jsonData.pages[0].questions[1].options[state.dialysisIndex].label)), _react["default"].createElement(_Primitives.Box, {
    style: _objectSpread(_objectSpread({}, _VascularAccessSelectionAssistantStyles.Styles.summaryLine), {}, {
      marginBottom: 30
    }),
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 375,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 376,
      columnNumber: 15
    }
  }, "Functionality: "), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 377,
      columnNumber: 15
    }
  }, jsonData.pages[0].questions[2].options[state.facilityIndex].label)), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.summaryLine,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 384,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 385,
      columnNumber: 15
    }
  }, "BMI: "), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 386,
      columnNumber: 15
    }
  }, jsonData.pages[1].questions[0].options[state.bmiIndex].label)), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.summaryLine,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 390,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 391,
      columnNumber: 15
    }
  }, "Cephalic Vein: "), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 392,
      columnNumber: 15
    }
  }, jsonData.pages[1].questions[1].options[state.cephalicIndex].label)), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.summaryLine,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 399,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 400,
      columnNumber: 15
    }
  }, "Basilic Vein: "), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 401,
      columnNumber: 15
    }
  }, jsonData.pages[1].questions[2].options[state.basilicIndex].label)), _react["default"].createElement(_Primitives.Box, {
    style: _objectSpread(_objectSpread({}, _VascularAccessSelectionAssistantStyles.Styles.summaryLine), {}, {
      marginBottom: 30,
      paddingBottom: 30,
      borderWidth: 0,
      borderBottomWidth: 1,
      borderBottomColor: _utils.Colors.BLACK,
      borderStyle: 'solid'
    }),
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 408,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _VascularAccessSelectionAssistantStyles.Styles.summarySlot,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 418,
      columnNumber: 15
    }
  }, "Artery Radial: "), _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 419,
      columnNumber: 15
    }
  }, jsonData.pages[1].questions[3].options[state.arteryIndex].label))), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.section,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 427,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppText["default"], {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 428,
      columnNumber: 13
    }
  }, "What is your role?"), _react["default"].createElement(_Primitives.RadioButton, {
    deactiveColor: _utils.Colors.PRIMARY_MEDIUM,
    box: false,
    style: _VascularAccessSelectionAssistantStyles.Styles.option,
    options: roles,
    activeColor: _utils.Colors.PRIMARY_MEDIUM,
    onSelected: function onSelected(e, i) {
      dispatch({
        type: 'field',
        field: 'roleIndex',
        value: e ? e.label : i
      });
    },
    value: state.roleIndex,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 429,
      columnNumber: 13
    }
  }))), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.backAndNextSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 447,
      columnNumber: 7
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.backButtonSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 448,
      columnNumber: 9
    }
  }, state.activePage > 1 && _react["default"].createElement(_AppButton["default"], {
    containerStyle: _VascularAccessSelectionAssistantStyles.Styles.cancelButton,
    onPress: function onPress() {
      dispatch({
        type: 'field',
        field: 'activePage',
        value: state.activePage - 1
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 450,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppIcon["default"], {
    size: "medium",
    name: "arrow-left",
    color: _utils.Colors.PRIMARY_MEDIUM,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 459,
      columnNumber: 15
    }
  }), _react["default"].createElement(_AppText["default"], {
    containerStyle: _VascularAccessSelectionAssistantStyles.Styles.buttonTextContainer,
    textStyle: {
      color: _utils.Colors.PRIMARY_MEDIUM
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 463,
      columnNumber: 15
    }
  }, ' ', "Back"))), _react["default"].createElement(_Primitives.Box, {
    style: _VascularAccessSelectionAssistantStyles.Styles.nextButtonSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 472,
      columnNumber: 9
    }
  }, state.activePage <= numberOfPages && _react["default"].createElement(_AppButton["default"], {
    disabled: isNextDisabled(),
    containerStyle: _VascularAccessSelectionAssistantStyles.Styles.nextButton,
    onPress: function onPress() {
      dispatch({
        type: 'field',
        field: 'activePage',
        value: state.activePage + 1
      });
      props.onNextClick();
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 474,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    containerStyle: _VascularAccessSelectionAssistantStyles.Styles.buttonTextContainer,
    textStyle: {
      color: _utils.Colors.WHITE_LIGHT
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 485,
      columnNumber: 15
    }
  }, "Next", ' '), _react["default"].createElement(_AppIcon["default"], {
    size: "medium",
    name: "arrow-right",
    color: _utils.Colors.WHITE_LIGHT,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 490,
      columnNumber: 15
    }
  })), state.activePage == numberOfPages + 1 && _react["default"].createElement(_AppButton["default"], {
    containerStyle: _VascularAccessSelectionAssistantStyles.Styles.nextButton,
    onPress: function onPress() {
      handleSubmit();
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 497,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppIcon["default"], {
    size: "medium",
    name: "check",
    color: _utils.Colors.WHITE_LIGHT,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 502,
      columnNumber: 15
    }
  }), _react["default"].createElement(_AppText["default"], {
    containerStyle: _VascularAccessSelectionAssistantStyles.Styles.buttonTextContainer,
    textStyle: {
      color: _utils.Colors.WHITE_LIGHT
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 506,
      columnNumber: 15
    }
  }, "Done")))), _react["default"].createElement(_AppButton["default"], {
    containerStyle: _VascularAccessSelectionAssistantStyles.Styles.cancelButton,
    onPress: function onPress() {
      props.navigate({
        screen: _application.NavigationScreen.HOME,
        props: {}
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 515,
      columnNumber: 7
    }
  }, _react["default"].createElement(_AppIcon["default"], {
    size: "medium",
    name: "times",
    color: _utils.Colors.PRIMARY_MEDIUM,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 520,
      columnNumber: 9
    }
  }), _react["default"].createElement(_AppText["default"], {
    containerStyle: _VascularAccessSelectionAssistantStyles.Styles.buttonTextContainer,
    textStyle: {
      color: _utils.Colors.PRIMARY_MEDIUM
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 524,
      columnNumber: 9
    }
  }, ' ', "Cancel")));
};

var _default = VascularAccessSelectionAssistant;
exports["default"] = _default;