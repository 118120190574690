"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AppNavigationHeaderStyles = exports.AppTabStyles = exports.AppInputStyles = exports.AppSubmitButtonStyles = exports.AppFormFieldStyles = exports.AppFormStyles = exports.AppErrorMessageStyles = exports.AppTextStyles = exports.AppButtonStyles = exports.AppStyles = void 0;

var Colors = _interopRequireWildcard(require("../utils/Colors"));

var Typography = _interopRequireWildcard(require("../utils/Typography"));

var Spacing = _interopRequireWildcard(require("../utils/Spacing"));

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var AppStyles = {
  row: {
    flexDirection: 'row'
  }
};
exports.AppStyles = AppStyles;
var AppButtonStyles = {
  button: {
    width: 200,
    height: 40,
    borderRadius: 30,
    backgroundColor: Colors.WHITE,
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "row",
    marginVertical: 7
  },
  icon: {
    marginRight: 3
  }
};
exports.AppButtonStyles = AppButtonStyles;
var AppTextStyles = {
  container: {
    flexDirection: "row",
    display: 'flex',
    alignItems: 'center',
    justifyContent: "center"
  },
  iconContainer: {
    justifyContent: "center",
    alignItems: 'center',
    display: 'flex',
    marginLeft: 7,
    marginRight: 7
  },
  textStyle: {
    fontFamily: Typography.FONT_FAMILY_REGULAR,
    fontWeight: "400",
    fontSize: Spacing.FONT_SIZE_16
  }
};
exports.AppTextStyles = AppTextStyles;
var AppErrorMessageStyles = {
  error: {
    color: "red"
  }
};
exports.AppErrorMessageStyles = AppErrorMessageStyles;
var AppFormStyles = {
  form: {}
};
exports.AppFormStyles = AppFormStyles;
var AppFormFieldStyles = {
  container: {
    marginVertical: 10
  }
};
exports.AppFormFieldStyles = AppFormFieldStyles;
var AppSubmitButtonStyles = {
  submitButton: {
    borderRadius: 10,
    backgroundColor: Colors.PRIMARY_MEDIUM
  }
};
exports.AppSubmitButtonStyles = AppSubmitButtonStyles;
var AppInputStyles = {
  container: {},
  input: {
    borderColor: Colors.GRAY_MEDIUM,
    borderWidth: 2,
    borderRadius: 10
  },
  label: {
    paddingBottom: 7,
    fontSize: Spacing.FONT_SIZE_16
  }
};
exports.AppInputStyles = AppInputStyles;
var AppTabStyles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    borderColor: Colors.PRIMARY,
    borderWidth: 2,
    borderStyle: 'solid',
    borderRadius: 10,
    backgroundColor: Colors.WHITE,
    webkitBoxShadow: "3px 2px 2px #9E9E9E",
    mozBoxShadow: "3px 2px 2px #9E9E9E",
    boxShadow: "3px 2px 2px #9E9E9E",
    elevation: 11
  },
  iconContainer: {
    flex: 1,
    flexShrink: 1,
    justifyContent: "center",
    alignItems: "center",
    display: 'flex'
  },
  textContainer: {
    flex: 1,
    justifyContent: "center",
    display: 'flex',
    alignItems: 'center'
  },
  contentText: {
    color: Colors.PRIMARY,
    fontWeight: "500",
    fontSize: 20,
    textAlign: 'center',
    alignItems: 'center',
    flex: 1,
    display: 'flex'
  }
};
exports.AppTabStyles = AppTabStyles;
var AppNavigationHeaderStyles = {
  container: {
    borderBottomColor: Colors.GRAY_SPACE,
    borderBottomWidth: 5,
    height: 50
  },
  textStyle: {},
  header: {},
  topButtons: {
    flexDirection: "row",
    justifyContent: "space-between",
    marginTop: 10,
    marginHorizontal: 10
  }
};
exports.AppNavigationHeaderStyles = AppNavigationHeaderStyles;