import React from "react";
import * as Colors from "@pairva/common/src/utils/Colors";

const SupportScreen = (props) => {

    const content = (
        <div style={styles.container}>
            <div style={{ marginTop: 50 }}>This application was built and is supported by Kopis USA.
                For all support matters contact us at <a href="mailto:Support@KopisUSA.com">Support@KopisUSA.com</a>.
                {' '} Visit our website at <a href="https://kopisusa.com">kopisusa.com</a>
            </div>
        </div>
    );

    return content;
};

export default SupportScreen;
const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        width: "100%",
        backgroundColor: Colors.WHITE_LIGHT,
    }
};
