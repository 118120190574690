"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styles = void 0;

var _utils = require("../../utils");

var Styles = {
  container: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    backgroundColor: _utils.Colors.WHITE_LIGHT
  },
  title: {
    fontSize: _utils.Spacing.FONT_SIZE_24,
    justifySelf: 'center',
    alignSelf: 'center'
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 20,
    marginBottom: 20
  },
  pageBody: {},
  section: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'flex-start',
    marginBottom: 10
  },
  option: {
    marginBottom: 20,
    marginLeft: 40
  },
  nextButton: {
    backgroundColor: _utils.Colors.PRIMARY_MEDIUM,
    width: '60%',
    display: 'flex'
  },
  backAndNextSection: {
    display: "flex",
    flexDirection: 'row',
    marginBottom: 20
  },
  backButtonSection: {
    display: "flex",
    flex: 1,
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 15
  },
  nextButtonSection: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 15,
    marginLeft: 10
  },
  cancelButton: {
    display: "flex",
    alignSelf: 'center',
    width: '60%',
    backgroundColor: _utils.Colors.WHITE_LIGHT
  },
  summaryLine: {
    display: "flex",
    marginBottom: 10
  },
  summarySlot: {
    fontWeight: 'bold',
    paddingRight: 10
  },
  summarySection: {},
  buttonTextContainer: {
    paddingLeft: 6,
    paddingRight: 6
  },
  moreInfoContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  moreInfoText: {
    marginLeft: 4,
    color: _utils.Colors.ORANGE
  },
  moreInfoItemContainer: {
    marginTop: 10,
    marginBottom: 10
  },
  moreInfoItemText: {
    fontWeight: 'bold'
  }
};
exports.Styles = Styles;