"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _Primitives = require("../primitives/Primitives");

var _ClinicalSituationsHomeStyles = require("../styles/ClinicalSituationsHome/ClinicalSituationsHomeStyles");

var _application = require("../models/application");

var _AppText = _interopRequireDefault(require("./AppText"));

var _utils = require("../utils");

var _clinical_situations = _interopRequireDefault(require("../data/clinical_situations.json"));

var _AppButton = _interopRequireDefault(require("./AppButton"));

var _this = void 0,
    _jsxFileName = "/home/vsts/work/1/s/common/build/web/temp/components/ClinicalSituationsHome.tsx";

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var ClinicalSituationsHome = function ClinicalSituationsHome(props) {
  var _useState = (0, _react.useState)(-1),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      selectedRadioButton = _useState2[0],
      setSelectedRadioButton = _useState2[1];

  var findOptionsIndex = function findOptionsIndex(optionsArray, stateId) {
    var index = optionsArray.findIndex(function (element) {
      return element.id == stateId;
    });
    return index;
  };

  var handleBackButtonClick = function handleBackButtonClick() {
    props.navigate({
      screen: _application.NavigationScreen.HOME,
      props: {}
    });
  };

  var handleNextButtonClick = function handleNextButtonClick() {
    props.navigate({
      screen: _application.NavigationScreen.CSAFLOW,
      props: {
        algorithmName: selectedRadioButton
      }
    });
  };

  var handleRadioButtonClick = function handleRadioButtonClick(event) {
    if (event) {
      setSelectedRadioButton(event.id);
    }
  };

  return _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsHomeStyles.Styles.container,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 52,
      columnNumber: 5
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsHomeStyles.Styles.body,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 53,
      columnNumber: 7
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsHomeStyles.Styles.title,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 54,
      columnNumber: 9
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _ClinicalSituationsHomeStyles.Styles.titleText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 55,
      columnNumber: 11
    }
  }, "Vascular Access Algorithms by Clinical Situation")), _react["default"].createElement(_AppText["default"], {
    textStyle: _ClinicalSituationsHomeStyles.Styles.subTitle,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 59,
      columnNumber: 9
    }
  }, "The right access in the right patient at the right time for the right reasons."), _react["default"].createElement(_Primitives.TouchableBox, {
    style: _ClinicalSituationsHomeStyles.Styles.moreAboutProcessSection,
    onPress: function onPress() {
      props.navigate({
        screen: _application.NavigationScreen.ABOUT,
        props: {}
      });
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 63,
      columnNumber: 9
    }
  }, _react["default"].createElement(_AppText["default"], {
    iconSize: "medium",
    iconName: "angle-double-right",
    iconColor: _utils.Colors.ORANGE,
    textStyle: {
      color: _utils.Colors.ORANGE
    },
    alignRight: true,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 64,
      columnNumber: 11
    }
  }, "More about our process", ' ')), _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsHomeStyles.Styles.radioButtonSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 73,
      columnNumber: 9
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: {
      fontWeight: 'bold'
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 74,
      columnNumber: 11
    }
  }, "Select a situation:"), _react["default"].createElement(_Primitives.RadioButton, {
    deactiveColor: _utils.Colors.PRIMARY_MEDIUM,
    box: false,
    style: _ClinicalSituationsHomeStyles.Styles.option,
    options: _clinical_situations["default"],
    activeColor: _utils.Colors.PRIMARY_MEDIUM,
    onSelected: function onSelected(e) {
      handleRadioButtonClick(e);
    },
    paddingSize: 0,
    value: findOptionsIndex(_clinical_situations["default"], props.algorithmName),
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 77,
      columnNumber: 11
    }
  })), _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsHomeStyles.Styles.backAndNextSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 90,
      columnNumber: 9
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsHomeStyles.Styles.backButtonSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 91,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppButton["default"], {
    onPress: handleBackButtonClick,
    containerStyle: _ClinicalSituationsHomeStyles.Styles.cancelButton,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 92,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: {
      color: _utils.Colors.PRIMARY_MEDIUM
    },
    iconSize: "medium",
    iconName: "times",
    iconColor: _utils.Colors.PRIMARY_MEDIUM,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 95,
      columnNumber: 15
    }
  }, "Cancel"))), _react["default"].createElement(_Primitives.Box, {
    style: _ClinicalSituationsHomeStyles.Styles.nextButtonSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 104,
      columnNumber: 11
    }
  }, _react["default"].createElement(_AppButton["default"], {
    disabled: selectedRadioButton == -1,
    containerStyle: _ClinicalSituationsHomeStyles.Styles.nextButton,
    onPress: handleNextButtonClick,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 105,
      columnNumber: 13
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: {
      color: _utils.Colors.WHITE_LIGHT
    },
    iconSize: "medium",
    iconName: "arrow-right",
    iconColor: _utils.Colors.WHITE_LIGHT,
    alignRight: true,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 109,
      columnNumber: 15
    }
  }, "Next"))))));
};

var _default = ClinicalSituationsHome;
exports["default"] = _default;