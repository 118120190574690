"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.clinicalSituations = exports.VAS_RESULT_DTO_TO_DISPLAY = exports.VAS_CHOICE_TO_LABEL = exports.ACCESS_POINT_DISPLAY = exports.RESULT_TO_STARS = exports.NavigationScreen = void 0;
var NavigationScreen = {
  MENU: 0,
  HOME: 1,
  VAS: 2,
  ABOUT: 3,
  RESOURCES: 4,
  HISTORY: 5,
  TERMS: 6,
  PRIVACY: 7,
  CSA: 8,
  LOGIN: 9,
  VASRESULTS: 10,
  REGISTER: 11,
  CSAFLOW: 12,
  EDITPROFILE: 13,
  SIGNOUT: 14
};
exports.NavigationScreen = NavigationScreen;
var RESULT_TO_STARS = {
  APPROPRIATE: 5,
  INDETERMINATE: 3,
  INAPPROPRIATE: 1
};
exports.RESULT_TO_STARS = RESULT_TO_STARS;
var ACCESS_POINT_DISPLAY = {
  brachial_brachial_transposition: 'Brachial-brachial Transposition',
  brachial_cephalic_fistula: 'Brachial-cephalic Fistula',
  brachial_cephalic_av_fistula: 'Brachial-cephalic AV Fistula',
  catheter: 'Catheter',
  chest_wall_av_graft: 'Chest Wall AV Graft',
  forearm_basilic_vein_transposition: 'Forearm Basilic Vein Transposition',
  forearm_loop_av_graft: 'Forearm Loop AV Graft',
  hero: 'HeRO',
  lower_extremity_av_fistula: 'Lower Extremity AV Fistula',
  lower_extremity_av_graft: 'Lower Extremity AV Graft',
  radial_cephalic_fistula: 'Radial-cephalic Fistula',
  radial_cephalic_av_fistula: 'Radial-cephalic AV Fistula',
  upper_arm_av_graft: 'Upper Arm AV Graft',
  upper_arm_basilic_vein_transposition: 'Upper Arm Basilic Vein Transposition'
};
exports.ACCESS_POINT_DISPLAY = ACCESS_POINT_DISPLAY;
var VAS_CHOICE_TO_LABEL = {
  '59DOWN': '59 yrs or less',
  '60TO75': '60 to 75 yrs',
  '76UP': '76 yrs or more',
  '2DOWN': '<2mm',
  '2UP': '>2mm',
  'INDEPENDENT': 'Independent',
  'DEPENDENTHOME': 'Dependent (Home)',
  'DEPENDENTFACIL': 'Dependent (Facility)',
  'POOR': 'Poor',
  'NORMAL': 'Normal',
  'OBESE': 'Obese',
  'MORBID': 'Morbidly Obese',
  'INTERMEDIATE': 'Intermediate',
  'GOOD': 'Good',
  0: 'No',
  1: 'Yes'
};
exports.VAS_CHOICE_TO_LABEL = VAS_CHOICE_TO_LABEL;
var VAS_RESULT_DTO_TO_DISPLAY = {
  BrachialBrachialTransposition: 'Brachial-brachial Transposition',
  BrachialCephalicAVFistula: 'Brachial-cephalic Fistula',
  BrachialCephalicFistula: 'Brachial-cephalic AV Fistula',
  Catheter: 'Catheter',
  ChestWallAvGraft: 'Chest Wall AV Graft',
  ForearmBasilicVeinTransposition: 'Forearm Basilic Vein Transposition',
  ForearmLoopAVGraft: 'Forearm Loop AV Graft',
  Hero: 'HeRO',
  LowerExtremityAVFistula: 'Lower Extremity AV Fistula',
  LowerExtremityAVGraft: 'Lower Extremity AV Graft',
  RadialCephalicFistula: 'Radial-cephalic Fistula',
  RadialCephalicAVFIstula: 'Radial-cephalic AV Fistula',
  UpperArmAVGraft: 'Upper Arm AV Graft',
  UpperArmBasilicVeinTransposition: 'Upper Arm Basilic Vein Transposition'
};
exports.VAS_RESULT_DTO_TO_DISPLAY = VAS_RESULT_DTO_TO_DISPLAY;
var clinicalSituations = {
  PRE_KRT: 1,
  HEMO_WITH_CVC: 2,
  HEMO_WITH_FAILING_AV: 3,
  TRANSPLANT_FOR_HEMO: 4,
  PERI_DIALYSIS_FOR_HEMO: 5
};
exports.clinicalSituations = clinicalSituations;