"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("@babel/runtime/helpers/toConsumableArray"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = _interopRequireWildcard(require("react"));

var _Primitives = require("../primitives/Primitives");

var _HistoryStyles = require("../styles/History/HistoryStyles");

var _utils = require("../utils");

var _AppIcon = _interopRequireDefault(require("./AppIcon"));

var _AppText = _interopRequireDefault(require("./AppText"));

var _this = void 0,
    _jsxFileName = "/home/vsts/work/1/s/common/build/web/temp/components/HistoryGrid.tsx";

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

var HistoryGrid = function HistoryGrid(props) {
  var _useState = (0, _react.useState)(true),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      loading = _useState2[0],
      setLoading = _useState2[1];

  var _useState3 = (0, _react.useState)([]),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      dataSource = _useState4[0],
      setDataSource = _useState4[1];

  var _useState5 = (0, _react.useState)(1),
      _useState6 = (0, _slicedToArray2["default"])(_useState5, 2),
      offset = _useState6[0],
      setOffset = _useState6[1];

  var _useState7 = (0, _react.useState)(false),
      _useState8 = (0, _slicedToArray2["default"])(_useState7, 2),
      showFilters = _useState8[0],
      setShowFilters = _useState8[1];

  (0, _react.useEffect)(function () {
    return getData();
  }, []);

  var getData = function getData() {
    console.log('getData');
    setLoading(true);
    fetch('https://run.mocky.io/v3/a0f18125-66ec-4058-b48d-25fd2df2400a/').then(function (response) {
      return response.json();
    }).then(function (responseJson) {
      setOffset(offset + 1);
      console.log(responseJson);
      setDataSource([].concat((0, _toConsumableArray2["default"])(dataSource), (0, _toConsumableArray2["default"])(responseJson.data)));
      setLoading(false);
    })["catch"](function (error) {
      console.error(error);
    });
  };

  var renderFooter = function renderFooter() {
    return _react["default"].createElement(_Primitives.Box, {
      style: _HistoryStyles.Styles.footer,
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 47,
        columnNumber: 7
      }
    }, _react["default"].createElement(_Primitives.TouchableBox, {
      activeOpacity: 0.9,
      onPress: getData,
      style: _HistoryStyles.Styles.loadMoreBtn,
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 48,
        columnNumber: 9
      }
    }, _react["default"].createElement(_Primitives.TextSpan, {
      style: {
        color: _utils.Colors.WHITE,
        textAlign: "center"
      },
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 53,
        columnNumber: 11
      }
    }, "Load More"), loading ? _react["default"].createElement(_Primitives.TextSpan, {
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 55,
        columnNumber: 13
      }
    }, "Loading...") : null));
  };

  var renderHistoryTab = function renderHistoryTab(item) {
    return _react["default"].createElement(_Primitives.Box, {
      style: _HistoryStyles.Styles.historyTab,
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 64,
        columnNumber: 7
      }
    }, _react["default"].createElement(_Primitives.Box, {
      style: _HistoryStyles.Styles.historyTabText,
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 65,
        columnNumber: 9
      }
    }, _react["default"].createElement(_Primitives.Box, {
      style: {},
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 66,
        columnNumber: 11
      }
    }, _react["default"].createElement(_Primitives.TextSpan, {
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 67,
        columnNumber: 13
      }
    }, item.date)), _react["default"].createElement(_Primitives.Box, {
      style: _HistoryStyles.Styles.vapsidBox,
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 69,
        columnNumber: 11
      }
    }, _react["default"].createElement(_Primitives.TextSpan, {
      style: {
        fontWeight: "bold"
      },
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 70,
        columnNumber: 13
      }
    }, "VAPSID: "), _react["default"].createElement(_Primitives.TextSpan, {
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 71,
        columnNumber: 13
      }
    }, item.VAPSID))), _react["default"].createElement(_Primitives.Box, {
      style: {
        alignSelf: "flex-end",
        position: "absolute"
      },
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 74,
        columnNumber: 9
      }
    }, _react["default"].createElement(_AppIcon["default"], {
      size: "xxl",
      name: "caret-right",
      color: _utils.Colors.PRIMARY_MEDIUM,
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 75,
        columnNumber: 11
      }
    })));
  };

  var _useState9 = (0, _react.useState)(new Date()),
      _useState10 = (0, _slicedToArray2["default"])(_useState9, 2),
      resultsDateFrom = _useState10[0],
      setResultsDateFrom = _useState10[1];

  var _useState11 = (0, _react.useState)(new Date()),
      _useState12 = (0, _slicedToArray2["default"])(_useState11, 2),
      resultsDateTo = _useState12[0],
      setResultsDateTo = _useState12[1];

  return _react["default"].createElement(_Primitives.Box, {
    style: _HistoryStyles.Styles.container,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 88,
      columnNumber: 5
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _HistoryStyles.Styles.container,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 89,
      columnNumber: 7
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: _HistoryStyles.Styles.titleSection,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 90,
      columnNumber: 9
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _HistoryStyles.Styles.title,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 91,
      columnNumber: 11
    }
  }, "Results History")), _react["default"].createElement(_Primitives.Box, {
    style: _HistoryStyles.Styles.filters,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 93,
      columnNumber: 9
    }
  }, _react["default"].createElement(_Primitives.Box, {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 94,
      columnNumber: 13
    }
  }, _react["default"].createElement(_Primitives.Box, {
    style: {
      flexDirection: "row",
      marginVertical: 10
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 95,
      columnNumber: 15
    }
  }, _react["default"].createElement(_Primitives.AppDatePicker, {
    onChange: setResultsDateFrom,
    value: resultsDateFrom,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 96,
      columnNumber: 17
    }
  }), _react["default"].createElement(_Primitives.TextSpan, {
    style: {
      fontWeight: "bold",
      alignSelf: "center",
      marginRight: 10,
      marginLeft: 10
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 101,
      columnNumber: 17
    }
  }, "To"), _react["default"].createElement(_Primitives.AppDatePicker, {
    onChange: setResultsDateTo,
    value: resultsDateTo,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 102,
      columnNumber: 17
    }
  })), _react["default"].createElement(_Primitives.TouchableBox, {
    style: _HistoryStyles.Styles.applyFilters,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 108,
      columnNumber: 15
    }
  }, _react["default"].createElement(_Primitives.TextSpan, {
    style: {
      color: _utils.Colors.WHITE,
      textAlign: "center"
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 109,
      columnNumber: 17
    }
  }, "Apply Date Filter")))), dataSource.map(function (item, index) {
    return _react["default"].createElement(_Primitives.TouchableBox, {
      key: index,
      onPress: function onPress() {},
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 116,
        columnNumber: 13
      }
    }, renderHistoryTab(item));
  }), renderFooter()));
};

var _default = HistoryGrid;
exports["default"] = _default;