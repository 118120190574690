"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styles = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _utils = require("../../utils");

var Styles = (0, _defineProperty2["default"])({
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: _utils.Colors.WHITE_LIGHT
  },
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontSize: _utils.Spacing.FONT_SIZE_24
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 20
  },
  section1: {
    paddingBottom: 10,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 12,
    paddingRight: 12
  },
  resourceTab: {
    flex: 1,
    display: 'flex',
    justifyContent: "center",
    flexDirection: 'column',
    borderStyle: 'solid',
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: _utils.Colors.GRAY_MEDIUM,
    paddingBottom: 10,
    paddingTop: 10
  },
  headerContainer: {
    display: 'flex',
    flexDirection: "row"
  },
  resourceTabText: {
    flexDirection: "row",
    display: "flex"
  },
  backButtonSection: {
    flexDirection: "row",
    alignItems: "center",
    marginVertical: 50,
    display: "flex"
  },
  pdfContainer: {
    marginLeft: 20
  }
}, "backButtonSection", {
  marginLeft: 20,
  marginTop: 10,
  width: "fit-content"
});
exports.Styles = Styles;