"use strict";

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var vasEnums = _interopRequireWildcard(require("../models/application"));

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function _createForOfIteratorHelper(o, allowArrayLike) {
  var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"];

  if (!it) {
    if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") {
      if (it) o = it;
      var i = 0;

      var F = function F() {};

      return {
        s: F,
        n: function n() {
          if (i >= o.length) return {
            done: true
          };
          return {
            done: false,
            value: o[i++]
          };
        },
        e: function e(_e) {
          throw _e;
        },
        f: F
      };
    }

    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }

  var normalCompletion = true,
      didErr = false,
      err;
  return {
    s: function s() {
      it = it.call(o);
    },
    n: function n() {
      var step = it.next();
      normalCompletion = step.done;
      return step;
    },
    e: function e(_e2) {
      didErr = true;
      err = _e2;
    },
    f: function f() {
      try {
        if (!normalCompletion && it["return"] != null) it["return"]();
      } finally {
        if (didErr) throw err;
      }
    }
  };
}

function _unsupportedIterableToArray(o, minLen) {
  if (!o) return;
  if (typeof o === "string") return _arrayLikeToArray(o, minLen);
  var n = Object.prototype.toString.call(o).slice(8, -1);
  if (n === "Object" && o.constructor) n = o.constructor.name;
  if (n === "Map" || n === "Set") return Array.from(o);
  if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen);
}

function _arrayLikeToArray(arr, len) {
  if (len == null || len > arr.length) len = arr.length;

  for (var i = 0, arr2 = new Array(len); i < len; i++) {
    arr2[i] = arr[i];
  }

  return arr2;
}

var chapterDefinitions = require('../data/chapter_definitions.json');

var chapterLookup = require('../data/chapter_lookup.json');

var VAS_RESULT_DTO_MAPPER = {
  brachial_brachial_transposition: 'BrachialBrachialTransposition',
  brachial_cephalic_fistula: 'BrachialCephalicFistula',
  brachial_cephalic_av_fistula: 'BrachialCephalicAVFistula',
  catheter: 'Catheter',
  chest_wall_av_graft: 'ChestWallAvGraft',
  forearm_basilic_vein_transposition: 'ForearmBasilicVeinTransposition',
  forearm_loop_av_graft: 'ForearmLoopAVGraft',
  hero: 'Hero',
  lower_extremity_av_fistula: 'LowerExtremityAVFistula',
  lower_extremity_av_graft: 'LowerExtremityAVGraft',
  radial_cephalic_fistula: 'RadialCephalicFistula',
  radial_cephalic_av_fistula: 'RadialCephalicAVFIstula',
  upper_arm_av_graft: 'UpperArmAVGraft',
  upper_arm_basilic_vein_transposition: 'UpperArmBasilicVeinTransposition'
};

var findChapterAndFollowupFields = function findChapterAndFollowupFields(results) {
  var ageTree = chapterLookup.find(function (age) {
    return age.query_value == results.age;
  });
  var dialysisTree = ageTree.possible_chapters.find(function (dialysis) {
    return dialysis.query_value == results.dialysis;
  });
  var cephalicTree = dialysisTree.possible_chapters.find(function (cephalic_vein) {
    return cephalic_vein.query_value == results.cephalic_vein;
  });

  if (cephalicTree["possible_chapters"] != undefined) {
    var basilicTree = cephalicTree.possible_chapters.find(function (basilic_vein) {
      return basilic_vein.query_value == results.basilic_vein;
    });
    var resultChapter = basilicTree.chapter;
    var followUpFields = basilicTree.follow_up_fields;
    return {
      chapterId: resultChapter,
      follow_up_fields: followUpFields
    };
  } else {
    var _resultChapter = cephalicTree.chapter;
    var _followUpFields = cephalicTree.follow_up_fields;
    return {
      chapterId: _resultChapter,
      follow_up_fields: _followUpFields
    };
  }
};

var calculateResults = function calculateResults(surveyResults) {
  var resultObject = findChapterAndFollowupFields(surveyResults);
  var chapterId = resultObject.chapterId;
  var followupFields = resultObject.follow_up_fields;
  var chapter = chapterDefinitions.find(function (chapterObj) {
    return chapterObj.chapter == chapterId;
  });
  var resultsDict = {};
  var dataDict = {};
  dataDict['Chapter'] = chapterId;

  var _iterator = _createForOfIteratorHelper(chapter.results),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var result = _step.value;
      var resultName = result.name;
      var resultDisplayName = vasEnums.ACCESS_POINT_DISPLAY[resultName];
      var resultRank = vasEnums.RESULT_TO_STARS[result.value];
      resultsDict[resultDisplayName] = resultRank;
      dataDict[VAS_RESULT_DTO_MAPPER[resultName]] = result.value;

      if (result["modifiers"] != undefined) {
        var _iterator2 = _createForOfIteratorHelper(result.modifiers),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var modifier = _step2.value;

            if (modifier.query_value == surveyResults[modifier.query]) {
              var modifiedRank = vasEnums.RESULT_TO_STARS[modifier.value];
              resultsDict[resultDisplayName] = modifiedRank;
              dataDict[VAS_RESULT_DTO_MAPPER[resultName]] = modifier.value;
              break;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  return {
    resultsDict: resultsDict,
    dataDict: dataDict
  };
};

var _default = {
  calculateResults: calculateResults
};
exports["default"] = _default;