import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemText } from '@material-ui/core';
import {
    NavigationScreen,
    useLocalStorage
} from "@pairva/common/build/web/dist";

const DrawerNav = (props) => {
    const [user, setUser] = useLocalStorage('user', undefined);

    return (
        <Drawer anchor={'right'} open={props.openDrawer}>
            <List>
                <ListItem button onClick={() =>
                    props.navigate({ screen: NavigationScreen.HOME, props: null })
                }>Home</ListItem>
                <ListItem button onClick={() =>
                    props.navigate({ screen: NavigationScreen.CSA, props: null })
                }>Clinical Situation Algorithms</ListItem>
                <ListItem button onClick={() =>
                    props.navigate({ screen: NavigationScreen.VAS, props: null })
                }>Vascular Access Selection Assistant</ListItem>
                <ListItem button onClick={() =>
                    props.navigate({ screen: NavigationScreen.RESOURCES, props: null })
                }>Resources</ListItem>
                {user &&
                    <ListItem button onClick={() =>
                        props.navigate({ screen: NavigationScreen.HISTORY, props: null })
                    }>History</ListItem>
                }
                <ListItem button onClick={() =>
                    props.navigate({ screen: NavigationScreen.ABOUT, props: null })
                }>About</ListItem>
                {user ?
                    <ListItem button onClick={() =>
                        props.navigate({ screen: NavigationScreen.SIGNOUT, props: null })
                    }>Sign Out</ListItem>
                    :
                    <ListItem button onClick={() =>
                        props.navigate({ screen: NavigationScreen.REGISTER, props: null })
                    }>Register / Sign In</ListItem>
                }
            </List>
        </Drawer>
    );
}

export default DrawerNav;