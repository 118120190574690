"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.VasPageData = void 0;
var VasPageData = {
  "pages": [{
    "page_title": "Patient Information",
    "page_number": 1,
    "questions": [{
      "id": "AGE",
      "title": "Patient Age",
      "options": [{
        "id": "59DOWN",
        "label": "59 years or younger"
      }, {
        "id": "60TO75",
        "label": "60 to 75 years"
      }, {
        "id": "76UP",
        "label": "76 years or older"
      }]
    }, {
      "id": "DIALYSIS",
      "title": "Need for Dialysis",
      "options": [{
        "id": "IMMINENT",
        "label": "Imminent / On dialysis"
      }, {
        "id": "NOT_IMMINENT",
        "label": "Not imminent"
      }]
    }, {
      "id": "FUNC_STATUS",
      "title": "Functional Status",
      "options": [{
        "id": "INDEPENDENT",
        "label": "Independent"
      }, {
        "id": "DEPENDENTHOME",
        "label": "Assisted (lives at home)"
      }, {
        "id": "DEPENDENTFACIL",
        "label": "Dependent (lives in a facility)"
      }]
    }]
  }, {
    "page_title": "Patient Anatomy",
    "page_number": 2,
    "questions": [{
      "id": "BMI",
      "title": "BMI",
      "options": [{
        "id": "NORMAL",
        "label": "Normal (less than 30.0)"
      }, {
        "id": "OBESE",
        "label": "Obese (30.0 to 40.0)"
      }, {
        "id": "MORBID",
        "label": "Morbidly Obese (40.0 or higher)"
      }]
    }, {
      "id": "CEPHALIC_VEIN",
      "title": "Cephalic Vein",
      "options": [{
        "id": "POOR",
        "label": "Poor ( < 2.0 mm)"
      }, {
        "id": "INTERMEDIATE",
        "label": "Intermediate (2.0 to 2.5 mm)"
      }, {
        "id": "GOOD",
        "label": "Good ( > 2.5 mm)"
      }]
    }, {
      "id": "BASILIC_VEIN",
      "title": "Basilic Vein",
      "options": [{
        "id": "POOR",
        "label": "Poor ( < 2.0 mm)"
      }, {
        "id": "INTERMEDIATE",
        "label": "Intermediate (2.0 to 2.5 mm)"
      }, {
        "id": "GOOD",
        "label": "Good ( > 2.5 mm)"
      }]
    }, {
      "id": "ARTERY_RADIAL",
      "title": "Artery Radial",
      "options": [{
        "id": "2DOWN",
        "label": "< 2.0 mm"
      }, {
        "id": "2UP",
        "label": "\u2265 2.0 mm"
      }]
    }]
  }]
};
exports.VasPageData = VasPageData;