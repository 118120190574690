import {
  Colors,
  KidneyCareLogo,
  PairVaLogo as PVLogo,
  FooterInfo,
  INavigation,
} from '@pairva/common/build/web/dist';
import {onNavigate} from '../util/util';
import {PairVaLogo} from '../assets/logos';
import React from 'react';
import {useHistory} from 'react-router-dom';

const Footer = (props) => {
  const history = useHistory();

  return (
    <>
      <div style={styles.footerContainer}>
        <div style={styles.footerBody}>
          <div style={styles.pairVaBranding}>
            <PVLogo
              pairVaLogo={<PairVaLogo width={225} height={75} />}></PVLogo>
          </div>
          <div style={styles.kidneyCareBranding}>
            <KidneyCareLogo
              kidneyLogo={
                <img
                  style={{
                    alignSelf: 'center',
                    width: 200,
                    marginBottom: 20,
                  }}
                  src={require('../assets/logos/logo-KCNI.png').default}
                />
              }></KidneyCareLogo>
          </div>
        </div>
        <div style={styles.footerInfo}>
          <FooterInfo
            navigate={(navigator: InstanceType<typeof INavigation>) =>
              onNavigate(history, navigator)
            }></FooterInfo>
        </div>
      </div>
    </>
  );
};

export default Footer;

const styles = {
  footerBody: {
    flex: 3,
    // marginLeft: '10%',
    // marginRight: '10%',
    flexDirection: 'row' as 'row',
    display: 'flex',
    padding: 30,
    width: '100%',
    backgroundColor: Colors.PRIMARY_MEDIUM,
  },
  footerInfo: {
    display: 'flex',
    width: '100%',
    flex: 1,
  },
  pairVaBranding: {
    flex: 1,
    justifyContent: 'flex-end',
    display: 'flex',
    marginRight: 45,
    marginTop: 10
  },
  kidneyCareBranding: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-start',
    borderWidth: 0,
    borderLeftWidth: 1,
    borderStyle: 'solid',
    borderLeftColor: Colors.WHITE_LIGHT,
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    height: '100%',
  },
};
