"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _apisauce = require("apisauce");

var _tokenContext = _interopRequireDefault(require("../context/tokenContext"));

var _useLocalStorage = _interopRequireDefault(require("../hooks/useLocalStorage"));

var _Constants = require("../utils/Constants");

var _react = require("react");

var axiosRetry = require('axios-retry');

var apiClient = (0, _apisauce.create)({
  baseURL: "https://pair-va-api.azurewebsites.net/"
});
axiosRetry(apiClient.axiosInstance, {
  retries: 3,
  retryDelay: function retryDelay(retryCount) {
    console.log("retry attempt: ".concat(retryCount));
    return retryCount * 2000;
  },
  retryCondition: function retryCondition(error) {
    console.log('Looking at retry condition', error.response.status !== 200);
    return error.response.status !== 200;
  }
});
var _default = apiClient;
exports["default"] = _default;