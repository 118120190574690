"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DropDown = exports.AppDatePicker = exports.Link = exports.RadioButton = exports.ListBox = exports.Icon = exports.InputBox = exports.ImageBox = exports.TouchableBox = exports.TextSpan = exports.Box = void 0;

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireWildcard(require("react"));

var MaterialDesign = _interopRequireWildcard(require("react-icons/md"));

var FontAwesome = _interopRequireWildcard(require("react-icons/fa"));

var _flatlistReact = _interopRequireDefault(require("flatlist-react"));

var _Util = require("../utils/Util");

var _reactDatePicker = _interopRequireDefault(require("react-date-picker"));

var _Button = _interopRequireDefault(require("@material-ui/core/Button"));

var _Menu = _interopRequireDefault(require("@material-ui/core/Menu"));

var _MenuItem = _interopRequireDefault(require("@material-ui/core/MenuItem"));

var _this = void 0,
    _jsxFileName = "/home/vsts/work/1/s/common/build/web/temp/primitives/Primitives.js";

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var Box = function Box(_ref) {
  var style = _ref.style,
      children = _ref.children,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["style", "children"]);
  return _react["default"].createElement("div", (0, _extends2["default"])({
    style: style
  }, otherProps, {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 15,
      columnNumber: 5
    }
  }), children);
};

exports.Box = Box;

var TextSpan = function TextSpan(_ref2) {
  var style = _ref2.style,
      children = _ref2.children,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref2, ["style", "children"]);
  return _react["default"].createElement("span", (0, _extends2["default"])({
    style: style
  }, otherProps, {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 24,
      columnNumber: 5
    }
  }), children);
};

exports.TextSpan = TextSpan;

var TouchableBox = function TouchableBox(_ref3) {
  var style = _ref3.style,
      onPress = _ref3.onPress,
      children = _ref3.children,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref3, ["style", "onPress", "children"]);
  return _react["default"].createElement("div", {
    style: (0, _extends2["default"])({
      cursor: "pointer"
    }, style),
    onClick: function onClick() {
      if (!otherProps.disabled) onPress();
    },
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 33,
      columnNumber: 5
    }
  }, children);
};

exports.TouchableBox = TouchableBox;

var ImageBox = function ImageBox(_ref4) {
  var style = _ref4.style,
      source = _ref4.source,
      alt = _ref4.alt,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref4, ["style", "source", "alt"]);
  return _react["default"].createElement("img", (0, _extends2["default"])({
    style: style,
    alt: alt,
    src: source
  }, otherProps, {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 43,
      columnNumber: 5
    }
  }));
};

exports.ImageBox = ImageBox;

var InputBox = function InputBox(_ref5) {
  var style = _ref5.style,
      value = _ref5.value,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref5, ["style", "value"]);
  return _react["default"].createElement("input", (0, _extends2["default"])({
    style: style,
    value: value
  }, otherProps, {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 50,
      columnNumber: 5
    }
  }));
};

exports.InputBox = InputBox;

var Icon = function Icon(props) {
  if (props.isMaterialIcon) {
    var MdIcon = MaterialDesign["MdNotInterested"];
    var iconFound = _Util.iconLookup[props.name];

    if (iconFound) {
      MdIcon = MaterialDesign[iconFound];
    }

    return _react["default"].createElement(MdIcon, (0, _extends2["default"])({
      style: props.style ? props.style : {
        fontSize: props.size,
        color: props.color
      }
    }, props, {
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 65,
        columnNumber: 7
      }
    }));
  } else {
    var FaIcon = FontAwesome["FaQuestion"];
    var _iconFound = _Util.iconLookup[props.name];

    if (_iconFound) {
      FaIcon = FontAwesome[_iconFound];
    }

    return _react["default"].createElement(FaIcon, (0, _extends2["default"])({
      style: props.style ? props.style : {
        fontSize: props.size,
        color: props.color
      }
    }, props, {
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 76,
        columnNumber: 7
      }
    }));
  }
};

exports.Icon = Icon;

var ListBox = function ListBox(props) {
  return _react["default"].createElement(_flatlistReact["default"], (0, _extends2["default"])({
    list: props.data,
    renderItem: function renderItem(item) {
      return props.renderItem(item);
    },
    renderWhenEmpty: function renderWhenEmpty() {
      return _react["default"].createElement("div", {
        __self: _this,
        __source: {
          fileName: _jsxFileName,
          lineNumber: 83,
          columnNumber: 102
        }
      });
    }
  }, props, {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 83,
      columnNumber: 5
    }
  }));
};

exports.ListBox = ListBox;

var RadioButton = function RadioButton(props) {
  var _props$style, _props$containerStyle, _props$boxStyle, _props$circleSize, _props$textStyle, _props$icon, _props$options, _props$deactiveColor, _props$activeColor, _props$onSelected, _props$value, _props$boxActiveBgCol, _props$boxDeactiveBgC, _props$box, _props$textColor, _props$paddingSize;

  var defaultProps = {
    style: {},
    boxStyle: {},
    containerStyle: {},
    textStyle: {},
    value: -1,
    circleSize: 18,
    options: [],
    animationTypes: [],
    onSelected: function onSelected() {},
    activeColor: '#03a9f4',
    deactiveColor: '#e2e2e2',
    boxActiveBgColor: '#e1f5fe33',
    boxDeactiveBgColor: '#fff',
    textColor: '#383838',
    box: true,
    paddingSize: 14
  };
  (0, _react.useEffect)(function () {
    selectedValueChanged(props.options[props.value], props.value);
  }, [props.value]);
  var style = (_props$style = props.style) !== null && _props$style !== void 0 ? _props$style : defaultProps.style;
  var containerStyle = (_props$containerStyle = props.containerStyle) !== null && _props$containerStyle !== void 0 ? _props$containerStyle : defaultProps.containerStyle;
  var boxStyle = (_props$boxStyle = props.boxStyle) !== null && _props$boxStyle !== void 0 ? _props$boxStyle : defaultProps.boxStyle;
  var circleSize = (_props$circleSize = props.circleSize) !== null && _props$circleSize !== void 0 ? _props$circleSize : defaultProps.circleSize;
  var textStyle = (_props$textStyle = props.textStyle) !== null && _props$textStyle !== void 0 ? _props$textStyle : defaultProps.textStyle;
  var icon = (_props$icon = props.icon) !== null && _props$icon !== void 0 ? _props$icon : defaultProps.icon;
  var options = (_props$options = props.options) !== null && _props$options !== void 0 ? _props$options : defaultProps.options;
  var deactiveColor = (_props$deactiveColor = props.deactiveColor) !== null && _props$deactiveColor !== void 0 ? _props$deactiveColor : defaultProps.deactiveColor;
  var activeColor = (_props$activeColor = props.activeColor) !== null && _props$activeColor !== void 0 ? _props$activeColor : defaultProps.activeColor;
  var onSelected = (_props$onSelected = props.onSelected) !== null && _props$onSelected !== void 0 ? _props$onSelected : defaultProps.onSelected;
  var value = (_props$value = props.value) !== null && _props$value !== void 0 ? _props$value : defaultProps.value;
  var boxActiveBgColor = (_props$boxActiveBgCol = props.boxActiveBgColor) !== null && _props$boxActiveBgCol !== void 0 ? _props$boxActiveBgCol : defaultProps.boxActiveBgColor;
  var boxDeactiveBgColor = (_props$boxDeactiveBgC = props.boxDeactiveBgColor) !== null && _props$boxDeactiveBgC !== void 0 ? _props$boxDeactiveBgC : defaultProps.boxDeactiveBgColor;
  var box = (_props$box = props.box) !== null && _props$box !== void 0 ? _props$box : defaultProps.box;
  var textColor = (_props$textColor = props.textColor) !== null && _props$textColor !== void 0 ? _props$textColor : defaultProps.textColor;
  var paddingSize = (_props$paddingSize = props.paddingSize) !== null && _props$paddingSize !== void 0 ? _props$paddingSize : defaultProps.paddingSize;

  var _useState = (0, _react.useState)(value),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      activeIndex = _useState2[0],
      setActiveIndex = _useState2[1];

  var selectedValueChanged = function selectedValueChanged(item, newValue) {
    setActiveIndex(newValue);
    onSelected(item, newValue);
  };

  var styles = {
    productBox: {
      display: 'flex',
      flexDirection: 'row',
      borderRadius: 7,
      borderWidth: 1,
      paddingLeft: 10,
      paddingRight: 10,
      paddingTop: 15,
      paddingBottom: 15,
      marginTop: 10,
      borderStyle: 'solid'
    },
    productBoxLess: {
      display: 'flex',
      flexDirection: 'row',
      marginTop: 10
    },
    leftProductBox: {
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },
    centerProductBox: {
      display: 'flex',
      flex: 16,
      justifyContent: 'flex-start',
      alignItems: 'center',
      paddingLeft: paddingSize,
      paddingRight: paddingSize
    },
    circle: {
      display: 'flex',
      borderWidth: 1,
      borderRadius: 10000,
      alignItems: 'center',
      justifyContent: 'center',
      borderStyle: 'solid'
    },
    icon: {
      display: 'flex',
      borderWidth: 1,
      borderRadius: 10000,
      alignItems: 'center',
      justifyContent: 'center',
      borderStyle: 'solid'
    },
    circleFill: {
      borderWidth: 1,
      borderRadius: 10000,
      borderStyle: 'solid'
    }
  };
  return _react["default"].createElement("div", {
    style: style,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 194,
      columnNumber: 5
    }
  }, options.map(function (item, index) {
    return _react["default"].createElement("div", {
      key: index,
      style: box ? _objectSpread(_objectSpread(_objectSpread({}, styles.productBox), {
        backgroundColor: activeIndex === index ? boxActiveBgColor : boxDeactiveBgColor,
        borderColor: activeIndex === index ? activeColor : deactiveColor
      }), boxStyle) : _objectSpread(_objectSpread({}, styles.productBoxLess), boxStyle),
      activeOpacity: 0.9,
      onClick: function onClick() {
        return selectedValueChanged(item, index);
      },
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 198,
        columnNumber: 13
      }
    }, _react["default"].createElement("div", {
      style: styles.leftProductBox,
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 214,
        columnNumber: 15
      }
    }, _react["default"].createElement("div", {
      style: icon ? _objectSpread(_objectSpread({}, styles.icon), {
        borderColor: activeIndex === index ? activeColor : deactiveColor,
        width: circleSize + 8,
        height: circleSize + 8
      }) : _objectSpread(_objectSpread({}, styles.circle), {
        borderColor: activeIndex === index ? activeColor : deactiveColor,
        width: circleSize + 8,
        height: circleSize + 8
      }),
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 215,
        columnNumber: 17
      }
    }, _react["default"].createElement("div", {
      style: {
        opacity: activeIndex === index ? 100 : 0
      },
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 231,
        columnNumber: 19
      }
    }, _react["default"].createElement("div", {
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 234,
        columnNumber: 21
      }
    }, icon ? icon : _react["default"].createElement("div", {
      style: _objectSpread(_objectSpread({}, styles.circleFill), {
        backgroundColor: activeIndex === index ? activeColor : deactiveColor,
        borderColor: activeIndex === index ? activeColor : deactiveColor,
        width: circleSize,
        height: circleSize
      }),
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 240,
        columnNumber: 27
      }
    }))))), _react["default"].createElement("div", {
      style: styles.centerProductBox,
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 256,
        columnNumber: 15
      }
    }, _react["default"].createElement("span", {
      style: _objectSpread(_objectSpread({}, {
        color: textColor
      }), textStyle),
      __self: _this,
      __source: {
        fileName: _jsxFileName,
        lineNumber: 257,
        columnNumber: 17
      }
    }, item.label)));
  }));
};

exports.RadioButton = RadioButton;

var Link = function Link(_ref6) {
  var children = _ref6.children,
      style = _ref6.style,
      href = _ref6.href,
      fileName = _ref6.fileName;
  return _react["default"].createElement("a", {
    style: style,
    href: href,
    download: fileName,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 275,
      columnNumber: 5
    }
  }, " ", children, " ");
};

exports.Link = Link;

var AppDatePicker = function AppDatePicker(props) {
  return _react["default"].createElement(_reactDatePicker["default"], {
    onChange: props.onChange,
    value: props.value,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 281,
      columnNumber: 5
    }
  });
};

exports.AppDatePicker = AppDatePicker;

var DropDown = function DropDown(props) {
  return _react["default"].createElement(_react["default"].Fragment, null, _react["default"].createElement(_Button["default"], {
    "aria-controls": "simple-menu",
    "aria-haspopup": "true",
    onClick: handleClick,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 291,
      columnNumber: 7
    }
  }, "Open Menu"), _react["default"].createElement(_Menu["default"], {
    id: "simple-menu",
    anchorEl: anchorEl,
    keepMounted: true,
    open: Boolean(anchorEl),
    onClose: handleClose,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 294,
      columnNumber: 7
    }
  }, _react["default"].createElement(_MenuItem["default"], {
    onClick: handleClose,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 301,
      columnNumber: 9
    }
  }, "Profile"), _react["default"].createElement(_MenuItem["default"], {
    onClick: handleClose,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 302,
      columnNumber: 9
    }
  }, "My account"), _react["default"].createElement(_MenuItem["default"], {
    onClick: handleClose,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 303,
      columnNumber: 9
    }
  }, "Logout")));
};

exports.DropDown = DropDown;