import {
  INavigation,
  Resources,
  Constants,
  useResources,
  useApi,
  VasApi,
  useLocalStorage,
} from "@pairva/common/build/web/dist";
import { useHistory } from "react-router-dom";
import React, { useState, useEffect } from "react";
import { FaCaretRight, FaArrowLeft } from "react-icons/fa";
import * as Colors from "@pairva/common/src/utils/Colors";
import { getValidToken } from "../util/tokenHandler";

// var retry = require("retry");
var Loader = require("react-loader");

const ResourcesScreen = (props) => {
  const history = useHistory();
  const { currentChapter, setCurrentChapterById } = useResources();
  const [chapterTrail, setChapterTrail] = useState([0]);
  const [currentCategory, setCurrentCategory] = useState("");
  const [resource, setResource] = useState(undefined);
  const [token, setToken] = useLocalStorage("authToken");
  const [isLoading, setIsLoading] = useState(true);

  const { data: testData, error, loading, request } = useApi(
    VasApi.getResourceBySection
  );

  const handleItemPress = (id) => {
    const newTrail = [...chapterTrail, id];
    setChapterTrail(newTrail);
    setCurrentChapterById(id);
  };

  const handleBackButton = () => {
    if (currentChapter && currentChapter.id == 0) {
      history.push("/home");
    }
    const newTrail = chapterTrail.slice(0, -1);
    setChapterTrail(newTrail);
    setCurrentChapterById(newTrail[newTrail.length - 1]);
    setResource(undefined);
    setIsLoading(true);
  };

  const getToken = async (): Promise<string> => {
    try {
      const validToken = await getValidToken(token);
      if (validToken) {
        setToken(validToken);
        return "Bearer " + validToken.access_token;
      } else {
        setToken(undefined);
        return token;
      }
    } catch (error) {
      setToken(undefined);
      return token;
    }
  };

  const getResource = async () => {
    try {
      const bearerToken = await getToken();
      VasApi.apiClient.setHeader('Authorization', bearerToken);
      const response = await request(currentChapter.index);
      const resourceUrl = JSON.parse(response.data)[0].URI.replaceAll(' ', '%20');
      setResource(resourceUrl);
      setIsLoading(false);
    } catch (error) {
    }
  }

  useEffect(() => {
    setCurrentChapterById(0);
  }, []);

  useEffect(() => {
    if (currentChapter?.title) {
      setCurrentCategory(`${currentChapter.index} ${currentChapter.title}`);
      if (!currentChapter.possible_chapters) {
        getResource();
      }
    } else {
      setCurrentCategory("");
    }
  }, [currentChapter]);

  const renderResourcesTab = (number, label) => {
    return (
      <div style={styles.resourceTab} onClick={() => {}}>
        <div style={styles.resourceTabText}>
          <div style={{ paddingRight: 10 }}>
            <div>{number}</div>
          </div>
          <div style={{}}>
            <div>{label}</div>
          </div>
        </div>
        <div style={{ alignSelf: "flex-end", position: "absolute" }}>
          <FaCaretRight size={30} color={Colors.PRIMARY_MEDIUM} />
        </div>
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <div style={styles.body}>
        <div style={styles.titleSection}>
          <div style={styles.title}>Resources</div>
        </div>
        <div style={styles.categorySection} onClick={handleBackButton}>
          <FaArrowLeft size={20} color={Colors.PRIMARY_MEDIUM} />
          <span style={styles.category}>{currentCategory}</span>
        </div>
        {currentChapter && (
          <div style={styles.section1}>
            {currentChapter.possible_chapters &&
              currentChapter.possible_chapters.map((item, i) => {
                return (
                  <div key={i} onClick={() => handleItemPress(item.id)}>
                    {renderResourcesTab(item.index, item.title)}
                  </div>
                );
              })}
          </div>
        )}
        {currentChapter && !currentChapter.possible_chapters && (
          <Loader loaded={!isLoading}>
            <div>
              <div style={styles.pdfContainer}>
                <a href={resource} target="_blank">
                  View {currentChapter.title}
                </a>
              </div>
            </div>
          </Loader>
        )}
      </div>
    </div>
  );
};

export default ResourcesScreen;
const styles = {
  container: {
    flex: 1,
    display: "flex",
    "flex-direction": "column",
    backgroundColor: Colors.WHITE_LIGHT,
  },
  body: {
    flex: 1,
    display: "flex",
    "flex-direction": "column",
  },
  title: {
    fontSize: 24,
  },
  titleSection: {
    display: "flex",
    "flex-direction": "column",
    marginTop: 20,
    marginBottom: 20,
    "align-items": "center",
  },
  section1: {
    paddingBottom: 10,
    display: "flex",
    "flex-direction": "column",
    paddingLeft: 12,
    paddingRight: 12,
  },
  resourceTab: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
    "flex-direction": "column",
    borderStyle: "solid",
    borderWidth: 0,
    borderBottomWidth: 1,
    borderBottomColor: Colors.GRAY_MEDIUM,
    paddingBottom: 10,
    paddingTop: 10,
    cursor: "pointer",
  },
  headerContainer: {
    display: "flex",
    "flex-direction": "row",
  },
  resourceTabText: {
    "flex-direction": "row",
    display: "flex",
  },
  pdfContainer: {
    marginLeft: 20,
  },
  backButtonSection: {
    marginLeft: 20,
    marginTop: 10,
    width: "fit-content",
  },
  categorySection: {
    marginLeft: 10,
    "flex-direction": "row",
    display: "flex",
    alignItems: "center",
    marginBottom: 5,
    cursor: "pointer",
  },
  category: {
    fontWeight: 600,
    color: Colors.PRIMARY_MEDIUM,
    paddingLeft: 5,
  },
};
