"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

var _typeof = require("@babel/runtime/helpers/typeof");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _extends2 = _interopRequireDefault(require("@babel/runtime/helpers/extends"));

var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));

var _objectWithoutProperties2 = _interopRequireDefault(require("@babel/runtime/helpers/objectWithoutProperties"));

var _react = _interopRequireDefault(require("react"));

var _Primitives = require("../primitives/Primitives");

var _AppStyles = require("../styles/AppStyles");

var _utils = require("../utils");

var Colors = _interopRequireWildcard(require("../utils/Colors"));

var _AppIcon = _interopRequireDefault(require("./AppIcon"));

var _AppText = _interopRequireDefault(require("./AppText"));

var _this = void 0,
    _jsxFileName = "/home/vsts/work/1/s/common/build/web/temp/components/AppTab.tsx";

function _getRequireWildcardCache(nodeInterop) {
  if (typeof WeakMap !== "function") return null;
  var cacheBabelInterop = new WeakMap();
  var cacheNodeInterop = new WeakMap();
  return (_getRequireWildcardCache = function _getRequireWildcardCache(nodeInterop) {
    return nodeInterop ? cacheNodeInterop : cacheBabelInterop;
  })(nodeInterop);
}

function _interopRequireWildcard(obj, nodeInterop) {
  if (!nodeInterop && obj && obj.__esModule) {
    return obj;
  }

  if (obj === null || _typeof(obj) !== "object" && typeof obj !== "function") {
    return {
      "default": obj
    };
  }

  var cache = _getRequireWildcardCache(nodeInterop);

  if (cache && cache.has(obj)) {
    return cache.get(obj);
  }

  var newObj = {};
  var hasPropertyDescriptor = Object.defineProperty && Object.getOwnPropertyDescriptor;

  for (var key in obj) {
    if (key !== "default" && Object.prototype.hasOwnProperty.call(obj, key)) {
      var desc = hasPropertyDescriptor ? Object.getOwnPropertyDescriptor(obj, key) : null;

      if (desc && (desc.get || desc.set)) {
        Object.defineProperty(newObj, key, desc);
      } else {
        newObj[key] = obj[key];
      }
    }
  }

  newObj["default"] = obj;

  if (cache) {
    cache.set(obj, newObj);
  }

  return newObj;
}

function ownKeys(object, enumerableOnly) {
  var keys = Object.keys(object);

  if (Object.getOwnPropertySymbols) {
    var symbols = Object.getOwnPropertySymbols(object);

    if (enumerableOnly) {
      symbols = symbols.filter(function (sym) {
        return Object.getOwnPropertyDescriptor(object, sym).enumerable;
      });
    }

    keys.push.apply(keys, symbols);
  }

  return keys;
}

function _objectSpread(target) {
  for (var i = 1; i < arguments.length; i++) {
    var source = arguments[i] != null ? arguments[i] : {};

    if (i % 2) {
      ownKeys(Object(source), true).forEach(function (key) {
        (0, _defineProperty2["default"])(target, key, source[key]);
      });
    } else if (Object.getOwnPropertyDescriptors) {
      Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
      ownKeys(Object(source)).forEach(function (key) {
        Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
      });
    }
  }

  return target;
}

var AppTab = function AppTab(_ref) {
  var children = _ref.children,
      iconName = _ref.iconName,
      containerStyle = _ref.containerStyle,
      isMaterialIcon = _ref.isMaterialIcon,
      otherProps = (0, _objectWithoutProperties2["default"])(_ref, ["children", "iconName", "containerStyle", "isMaterialIcon"]);
  return _react["default"].createElement(_Primitives.TouchableBox, (0, _extends2["default"])({
    style: _objectSpread(_objectSpread({}, _AppStyles.AppTabStyles.container), containerStyle)
  }, otherProps, {
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 25,
      columnNumber: 5
    }
  }), _react["default"].createElement(_Primitives.Box, {
    style: _AppStyles.AppTabStyles.iconContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 28,
      columnNumber: 7
    }
  }, _react["default"].createElement(_AppIcon["default"], {
    name: iconName,
    size: _utils.Constants.IS_WEB ? 'xxxl' : 'xxl',
    color: Colors.PRIMARY,
    isMaterialIcon: isMaterialIcon ? isMaterialIcon : false,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 29,
      columnNumber: 9
    }
  })), _react["default"].createElement(_Primitives.Box, {
    style: _AppStyles.AppTabStyles.textContainer,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 35,
      columnNumber: 7
    }
  }, _react["default"].createElement(_AppText["default"], {
    textStyle: _AppStyles.AppTabStyles.contentText,
    __self: _this,
    __source: {
      fileName: _jsxFileName,
      lineNumber: 36,
      columnNumber: 9
    }
  }, children)));
};

var _default = AppTab;
exports["default"] = _default;