import { HomeMenu, INavigation, useLocalStorage, IAuthToken } from '@pairva/common/build/web/dist';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { onNavigate } from '../util/util';
import { GetIAuthToken, getValidToken } from '../util/tokenHandler';
import jwt_decode from "jwt-decode";


const HomeScreen = (props) => {
  const history = useHistory();
  const [user, setUser] = useLocalStorage("user", undefined);
  const [token, setToken] = useLocalStorage("authToken", undefined);

  useEffect(() => {
    // If no user data, look for 'code' in url params
    if (!user) {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');

      // If 'code' url param exists
      // use it to get bearer token from Azure AD
      if (code) {
        getAuthToken(code);
      }
    } 
    else {
      // Get valid token for saved user
      validateToken();
    }
  }, []);

  const getAuthToken = async (code: string) => {
    // Get bearer token from Azure AD
    try {
      let authToken: IAuthToken = await GetIAuthToken(code);

      // Storing jwt token, jwt expiry, refresh token, and refresh token expiry
      // And user data
      setUser(jwt_decode(authToken.access_token));
      setToken(authToken);
  
      window.location.reload();
    } catch (error) {
    }
  };

  const validateToken = async () => {
    // Getting valid bearer token
    const validToken = await getValidToken(token);

    // If token is undefined, then the token and refresh token has expired
    // user will need to reauthenticate
    if (!validToken) {
      setUser(undefined); 
      setToken(undefined);
      window.location.reload();
    }
    else {
      // Store valid token and user data
      setUser(jwt_decode(validToken.access_token));
      setToken(validToken);
    }
  }


  const content = (
    <div style={styles.container}>
      <div style={styles.mainMenuContainer}>
        <HomeMenu
          navigate={(navigator: InstanceType<typeof INavigation>) =>
            onNavigate(history, navigator)
          }
          user={user}
          signedIn={user != undefined}
        />
      </div>
    </div>
  );

  return content;
};

export default HomeScreen;

const styles = {
  container: {
    height: '100%',
    display: 'contents',
    alignItems: 'center',
    justifyContent: 'center'
  },
  mainMenuContainer: {
    flex: 3,
    flexGrow: 1,
    display: 'flex',
  },
};
