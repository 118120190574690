"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.scaleFont = exports.scaleSize = void 0;

var scaleSize = function scaleSize(size) {
  return 1 * size;
};

exports.scaleSize = scaleSize;

var scaleFont = function scaleFont(size) {
  return size * 1;
};

exports.scaleFont = scaleFont;