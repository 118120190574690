"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styles = void 0;

var _utils = require("../../utils");

var Styles = {
  body: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingLeft: 12,
    paddingRight: 12
  },
  container: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: _utils.Colors.WHITE_LIGHT,
    alignItems: 'center',
    justifyContent: 'center'
  },
  nextButton: {
    backgroundColor: _utils.Colors.PRIMARY_MEDIUM,
    width: 100,
    display: 'flex'
  },
  nextButtonDisabled: {
    backgroundColor: _utils.Colors.PRIMARY_LIGHT,
    width: '60%',
    display: 'flex'
  },
  backAndNextSection: {
    display: "flex",
    flexDirection: 'row',
    marginBottom: 20
  },
  backButtonSection: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 15
  },
  nextButtonSection: {
    flex: 1,
    display: "flex",
    flexDirection: 'column',
    alignItems: 'center',
    marginRight: 15,
    marginLeft: 10
  },
  cancelButton: {
    display: "flex",
    width: '60%',
    alignSelf: 'center',
    backgroundColor: _utils.Colors.WHITE_LIGHT
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 10,
    marginBottom: 10
  },
  titleText: {
    fontSize: _utils.Spacing.FONT_SIZE_24,
    textAlign: 'center'
  },
  notesSection: {
    marginTop: 70,
    paddingBottom: 50,
    width: '60%',
    flexDirection: 'column',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center'
  },
  scrollViewSection: {
    flex: 1,
    display: 'flex'
  },
  questionText: {
    fontWeight: 'bold',
    textAlign: 'flex-start',
    flex: 1
  },
  outcomeSection: {
    marginVertical: 20,
    flexDirection: 'column',
    border: '2px solid #290A66',
    borderRadius: 15,
    width: 700,
    height: 150,
    backgroundColor: _utils.Colors.PRIMARY_LIGHT,
    alignItems: 'center'
  },
  followUpTextSection: {
    display: 'flex',
    justifyContent: 'center'
  },
  followUpText: {
    color: _utils.Colors.PRIMARY_MEDIUM,
    fontWeight: 'bold',
    paddingRight: 20
  },
  followUp: {
    marginTop: 20,
    paddingBottom: 30
  },
  questionsContainer: {
    justifyContent: 'center'
  },
  questionSection: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: 20,
    marginBottom: 20,
    justifyContent: 'flex-start'
  },
  nextStepsSection: {
    paddingTop: 30,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  subTitleSection: {
    display: 'flex',
    alignItems: 'center'
  },
  option: {},
  followUpSubText: {},
  subTitleText: {
    fontSize: 15
  },
  subTitleTextSmall: {
    fontSize: 10,
    verticalAlign: 'super'
  },
  outcomeLabelText: {
    fontWeight: 'bold',
    color: _utils.Colors.PRIMARY_MEDIUM,
    fontSize: 20,
    paddingBottom: 15
  },
  outcome: {
    fontSize: 20,
    textAlign: 'center'
  },
  outcomeIcon: {
    paddingTop: 20,
    paddingBottom: 10,
    justifyContent: 'center',
    textAlign: 'center'
  },
  notesLabelText: {
    fontWeight: 'bold',
    marginBottom: 5
  },
  nextStepsLabelText: {
    fontWeight: 'bold',
    marginTop: 20,
    marginBottom: 20
  },
  nextStepContainer: {
    marginTop: 15,
    marginBottom: 15,
    display: 'flex'
  },
  nextStepDescription: {
    display: 'flex',
    flex: 1,
    textAlign: 'flex-start'
  },
  followUpContainer: {
    marginLeft: 6
  },
  moreAboutProcessSection: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 5,
    marginBottom: 5,
    alignItems: 'center',
    alignSelf: 'center'
  }
};
exports.Styles = Styles;