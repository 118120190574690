"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.Styles = void 0;

var _utils = require("../../utils");

var Styles = {
  container: {
    display: 'flex',
    flex: 1,
    justifyContent: "center",
    flexDirection: 'column',
    maxWidth: 200,
    paddingLeft: 50
  },
  kidneyLogoText: {
    flex: 1,
    color: _utils.Colors.WHITE_LIGHT,
    textAlign: "center",
    alignSelf: "center"
  }
};
exports.Styles = Styles;