"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;

var _slicedToArray2 = _interopRequireDefault(require("@babel/runtime/helpers/slicedToArray"));

var _react = require("react");

var _pre_krt_algorithm = _interopRequireDefault(require("../data/pre_krt_algorithm.json"));

var _hemo_with_cvc_algorithm = _interopRequireDefault(require("../data/hemo_with_cvc_algorithm.json"));

var _on_hemo_failing_av_algorithm = _interopRequireDefault(require("../data/on_hemo_failing_av_algorithm.json"));

var _transplant_patient_for_hemo = _interopRequireDefault(require("../data/transplant_patient_for_hemo.json"));

var _peri_dialysis_patient_for_hemo_algorithm = _interopRequireDefault(require("../data/peri_dialysis_patient_for_hemo_algorithm.json"));

var useClinicalSituations = function useClinicalSituations(algorithm) {
  var _useState = (0, _react.useState)(null),
      _useState2 = (0, _slicedToArray2["default"])(_useState, 2),
      activeTree = _useState2[0],
      setActiveTree = _useState2[1];

  var _useState3 = (0, _react.useState)(null),
      _useState4 = (0, _slicedToArray2["default"])(_useState3, 2),
      currentChapter = _useState4[0],
      setCurrentChapter = _useState4[1];

  var _useState5 = (0, _react.useState)(1),
      _useState6 = (0, _slicedToArray2["default"])(_useState5, 2),
      currentChapterId = _useState6[0],
      setCurrentChapterById = _useState6[1];

  (0, _react.useEffect)(function () {
    switch (algorithm) {
      case "PRE_KRT":
        setActiveTree(_pre_krt_algorithm["default"]);
        break;

      case "HEMO_WITH_CVC":
        setActiveTree(_hemo_with_cvc_algorithm["default"]);
        break;

      case "HEMO_WITH_FAILING_AV":
        setActiveTree(_on_hemo_failing_av_algorithm["default"]);
        break;

      case "TRANSPLANT_FOR_HEMO":
        setActiveTree(_transplant_patient_for_hemo["default"]);
        break;

      case "PERI_DIALYSIS_FOR_HEMO":
        setActiveTree(_peri_dialysis_patient_for_hemo_algorithm["default"]);
        break;

      default:
        break;
    }
  }, []);
  (0, _react.useEffect)(function () {
    if (activeTree != undefined) {
      var chapter = getChapter(currentChapterId);
      setCurrentChapter(chapter);
    }
  }, [currentChapterId, activeTree]);

  var getChapter = function getChapter(chapterNumber) {
    var queue = [activeTree];

    while (queue.length) {
      var currentNode = queue.shift();

      if (currentNode.chapter == chapterNumber) {
        return currentNode;
      }

      if (currentNode.possible_chapters) {
        if (currentNode.possible_chapters[0]) {
          queue.push(currentNode.possible_chapters[0]);
        }

        if (currentNode.possible_chapters[1]) {
          queue.push(currentNode.possible_chapters[1]);
        }
      }
    }

    console.log("chapter not found");
  };

  return {
    setCurrentChapterById: setCurrentChapterById,
    currentChapter: currentChapter
  };
};

var _default = useClinicalSituations;
exports["default"] = _default;