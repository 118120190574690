"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.iconLookup = exports.safe = exports.formatDate = void 0;

var formatDate = function formatDate(dateToFormat) {
  var date = new Date(dateToFormat);
  var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  return months[date.getMonth()] + ' ' + date.getDay() + ', ' + date.getFullYear();
};

exports.formatDate = formatDate;

var safe = function safe(ob, dv) {
  try {
    var fn = function fn() {
      return ob;
    };

    if (fn()) {
      return fn();
    } else {
      return dv;
    }
  } catch (e) {
    return dv;
  }
};

exports.safe = safe;
var iconLookup = {
  'star-o': 'FaRegStar',
  star: 'FaStar',
  stethoscope: 'FaStethoscope',
  history: 'FaHistory',
  construction: 'FaTools',
  'info-circle': 'FaInfoCircle',
  'exclamation-circle': 'FaExclamationCircle',
  'user-circle': 'FaUserCircle',
  'sign-out': 'FaSignOutAlt',
  book: 'FaBookOpen',
  times: 'FaTimes',
  check: 'FaCheck',
  close: 'FaTimes',
  menu: 'MdMenu',
  'arrow-right': 'FaArrowRight',
  'arrow-left': 'FaArrowLeft',
  'caret-down': 'FaCaretDown',
  'caret-up': 'FaCaretUp',
  'angle-double-right': 'FaAngleDoubleRight',
  'caret-right': 'FaCaretRight',
  'file-pdf-o': 'FaRegFilePdf',
  'home': 'FaHome'
};
exports.iconLookup = iconLookup;